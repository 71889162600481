<div class="u-border-radius border-color tile-color-background u-padding-30">
  <div class="o-flex o-flex--align-items-center o-flex--justify-center">
    <div class="preview-metabolic-fingerprint__icon-container">
      <mat-icon class="preview-metabolic-fingerprint__icon-dimension" svgIcon="configurator-metabolic-fingerprint"></mat-icon>
      <div class="preview-metabolic-fingerprint__top-left"></div>
      <div class="preview-metabolic-fingerprint__top-right"></div>
      <div *ngIf="config?.left" class="preview-metabolic-fingerprint__bottom-left">{{config.left.name}}</div>
      <div *ngIf="config?.right" class="preview-metabolic-fingerprint__bottom-right">{{config.right.name}}</div>
      <div *ngIf="config?.center" class="preview-metabolic-fingerprint__bottom-center">{{config.center.name}}</div>
    </div>
  </div>
</div>
