<div class="feature">
  <div class="sub-title feature--margin-top-35">
      <span style="font-size: 14px">You have {{ listActiveFeature.length || 0 }} active features</span>
  </div>
  <ng-container *ngFor="let feature of listActiveFeature">
    <div>
      <mat-divider style="margin: 16px 0" *ngIf="!feature?.isShowDetail"></mat-divider>
      <div class="o-flex" *ngIf="!feature?.isShowDetail">
        <div class="feature__name">
          <mat-icon
            style="margin-right: 16px; cursor: pointer"
            (click)="feature.isShowDetail = true"
          >
            keyboard_arrow_down</mat-icon
          >
          <strong>{{ feature?.name }}</strong>
        </div>
        <fieldset class="feature__fees">
          <legend>Fees</legend>
          <div class="feature__label">
            {{ feature?.currency_name }} {{ feature?.price }} /
            {{
              feature.period_type_clone == 3
                ? "month"
                : feature.period_type_clone == 2
                  ? "quarter"
                  : "year"
            }}
          </div>
          <div class="feature__sub-label">
            billed
            {{
              feature.period_type_clone == 3
                ? "monthly"
                : feature.period_type_clone == 2
                  ? "quarterly"
                  : "yearly"
            }}
          </div>
        </fieldset>
        <fieldset class="o-flex feature__period">
          <legend>Period</legend>
          <div class="feature__field-text">
            <div class="feature__label">Start date</div>
            <div class="value">
              {{
                feature?.start_date
                  | dateFormatExt: constant.DATE_FORMAT.DATE_TIME_MOMENT:true
              }}
            </div>
          </div>

          <div *ngIf="!feature?.cancellation_date" class="feature__field-text">
            <div class="feature__label">Renewal date</div>
            <div class="value">
              {{
                feature?.expiration
                  | dateFormatExt: constant.DATE_FORMAT.DATE_TIME_MOMENT:true
              }}
            </div>
          </div>

          <div *ngIf="feature?.cancellation_date" class="feature__field-text">
            <div class="feature__label">Cancellation date</div>
            <div class="value">
              {{
                feature?.cancellation_date
                  | dateFormatExt: constant.DATE_FORMAT.DATE_TIME_MOMENT:true
              }}
            </div>
          </div>

          <div *ngIf="feature?.subscription_end_date" class="feature__field-text">
            <div class="feature__label">Subscription end date</div>
            <div class="value">
              {{
                feature?.subscription_end_date
                  | dateFormatExt: constant.DATE_FORMAT.DATE_TIME_MOMENT:true
              }}
            </div>
          </div>

          <div *ngIf="!feature?.cancellation_date" class="full-width" style="display: flex">
            <button
              mat-raised-button
              color="primary"
              style="margin-left: auto; margin-bottom: auto"
              (click)="cancelFeature(feature)"
            >
              Cancel
            </button>
          </div>
        </fieldset>
      </div>
      <ng-container *ngIf="feature?.isShowDetail">
        <div class="feature__detail-name">
          <mat-icon
            style="margin-right: 16px; cursor: pointer"
            (click)="feature.isShowDetail = false"
          >keyboard_arrow_up
          </mat-icon>
          <strong>{{ feature?.name }}</strong>
        </div>
        <mat-divider style="margin: 16px 0"></mat-divider>

        <div class="feature__detail-item border-color">
          <div class="feature__detail-name">Contract Period</div>

          <div class="o-flex">
            <div class="feature__date-group">
              <div class="feature__label">Purchase date</div>
              <div class="date">
                {{
                  feature?.created_at
                    | dateFormatExt: constant.DATE_FORMAT.DATE_TIME_MOMENT:true
                }}
              </div>
            </div>

            <div class="feature__date-group">
              <div class="feature__label">Start date</div>
              <div class="date">
                {{
                  feature?.start_date
                    | dateFormatExt: constant.DATE_FORMAT.DATE_TIME_MOMENT:true
                }}
              </div>
            </div>

            <div class="feature__date-group">
              <div class="feature__label">Next period starts</div>
              <div class="date">
                {{
                  feature?.expiration
                    | dateFormatExt: constant.DATE_FORMAT.DATE_TIME_MOMENT:true
                }}
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
