import { inject, Injectable } from '@angular/core';
import { ApiService } from "@core/services/api.service";
import { Observable, of } from "rxjs";
import { IFeature } from "@shared/components/interfaces/feature.interface";
import { AppConstants } from "@core/constants";
import { FeatureManagementService } from "@shared/services/feature-management/feature-management.service";
import { FeatureSlugEnum } from "@shared/enums/feature-slug.enum";

@Injectable()
export class MyInscydService {
  private apiService: ApiService = inject(ApiService);
  private featureManagementService: FeatureManagementService = inject(FeatureManagementService);

  private hardCodedSettingTileList: IFeature[];

  public getMyInscydFeatureList(): Observable<IFeature[]> {
    return this.apiService.get(AppConstants.API.MY_INSCYD_FEATURE_ITEM.GET);
  }

  public getHardCodedSettingTileList(): Observable<IFeature[]> {
    this.hardCodedSettingTileList = [
      {
        urlNavigateTo: '/home/administration/training-zone-template',
        id: 0,
        category_name: 'Apps',
        tags: ['Apps'],
        image: 'https://inscyd-prod-public.s3.eu-central-2.amazonaws.com/features/training_zone_builder/Training+Zone+Builder.png',
        is_published: true,
        category: 5,
        name: 'Training Zone Builder',
        is_recommended: false,
        marketing_features: [],
        dependencies: [],
        description_html: {},
        created_at: '',
        description: 'Create custom training zones to target specific training adaptations',
        discounts: [],
        is_delete: false,
        is_enabled: this.featureManagementService.getFeatureValue(FeatureSlugEnum.TRAINING_ZONE_BUILDER),
        links: [],
        slug: '',
        suggested_courses: [],
        summary: 'Training Zone Building',
        updated_at: '',
        waitlist: true,
        buttonName: 'set up',
      },
      {
        urlNavigateTo: '/home/custom-pdf-text',
        id: 0,
        category_name: 'Customization',
        tags: ['Customization'],
        image: 'https://inscyd-prod-public.s3.eu-central-2.amazonaws.com/features/pdf_report_texts/Custom+PDF+Text.png',
        is_published: true,
        category: 5,
        name: 'PDF Report Texts',
        is_recommended: false,
        marketing_features: [],
        dependencies: [],
        description_html: {},
        created_at: '',
        description: 'Create your own texts to show up on the PDF reports',
        discounts: [],
        is_delete: false,
        is_enabled: true,
        links: [],
        slug: '',
        suggested_courses: [],
        summary: 'Training Zone Building',
        updated_at: '',
        waitlist: true,
        buttonName: 'Customize',
      },
      {
        urlNavigateTo: '/home/custom-athlete-category',
        id: 0,
        category_name: 'Customization',
        tags: ['Beta'],
        image: 'https://inscyd-prod-public.s3.eu-central-2.amazonaws.com/features/custom_athlete_categories/Athlete+Category.png',
        is_published: true,
        category: 0,
        name: 'Athlete categories & grading',
        is_recommended: false,
        marketing_features: [],
        dependencies: [],
        description_html: {},
        created_at: '',
        description: 'Create custom categories for different group of athletes and set up custom grading system to be used in the dashboards',
        discounts: [],
        is_delete: false,
        is_enabled: this.featureManagementService.getFeatureValue(FeatureSlugEnum.CUSTOM_ATHLETE_CATEGORIES),
        links: [],
        slug: '',
        suggested_courses: [],
        summary: 'Custom Athlete Category',
        updated_at: '',
        waitlist: true,
        buttonName: 'Customize',
      },
    ];

    return of(this.hardCodedSettingTileList.filter((tile: IFeature) => tile.is_enabled));
  }
}
