import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { AppConstants } from "@core/constants";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ApiBaseService {
  private httpClient: HttpClient = inject(HttpClient);

  public jsonHeaders: Record<string, any> = { "Content-Type": "application/json" };

  public postJson<T>(
    url: string,
    body: any,
    additionalHeaders?: Record<string, any>,
    additionalHttpOptions?: Record<string, any>
  ): Observable<T> {
    const httpHeaders = { ...this.jsonHeaders, ...(additionalHeaders ?? {}), "Access-Control-Allow-Origin": "*" };
    const httpOptions = {
      headers: new HttpHeaders(httpHeaders),
      withCredentials: true,
      ...(additionalHttpOptions ?? {})
    };

    return this.post(url, body, httpOptions);
  }

  public putJson<T>(
    url: string,
    body: any,
    additionalHeaders?: Record<string, any>,
    additionalHttpOptions?: Record<string, any>
  ): Observable<T> {
    const httpHeaders = { ...this.jsonHeaders, ...(additionalHeaders ?? {}) };
    const httpOptions = {
      headers: new HttpHeaders(httpHeaders),
      withCredentials: true,
      ...(additionalHttpOptions ?? {})
    };

    return this.put(url, body, httpOptions);
  }

  public deleteJson<T>(
    url: string,
    additionalHeaders?: Record<string, any>,
    additionalHttpOptions?: Record<string, any>
  ): Observable<T> {
    const httpHeaders = { ...this.jsonHeaders, ...(additionalHeaders ?? {}) };
    const httpOptions = {
      headers: new HttpHeaders(httpHeaders),
      withCredentials: true,
      ...(additionalHttpOptions ?? {})
    };

    return this.delete(url, httpOptions);
  }

  private post<T>(url: string, body: any, httpOptions?: Record<string, any>): Observable<T> {
    return this.httpClient.post<T>(AppConstants.API_HOST + url, body, httpOptions);
  }

  private put<T>(url: string, body: any, httpOptions?: Record<string, any>): Observable<T> {
    return this.httpClient.put<T>(AppConstants.API_HOST + url, body, httpOptions);
  }

  private delete<T>(url: string, httpOptions?: Record<string, any>): Observable<T> {
    return this.httpClient.delete<T>(AppConstants.API_HOST + url, httpOptions);
  }
}