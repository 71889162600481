<div class="page-container">
  <app-custom-back-button *ngIf="tabIndexToReturn" [customUrl]="customUrl"></app-custom-back-button>
  <div class="o-flex o-flex--align-items-center u-margin-bottom-50">
    <div class="general-info--font-size-35 line-height-28 u-font-weight-400 u-margin-right-10">Custom Athlete Category</div>
    <app-label class="u-right-padding-5" *ngFor="let tag of tags" [text]="tag" [isBeta]="isBeta()"></app-label>
  </div>
  <div class="o-flex o-flex--align-items-center">
  </div>
  <mat-dialog-content>
    <div [formGroup]="form">
      <span class="u-font-size-14 u-font-weight-700 line-height-normal">Default categories</span>
      <hr>
      <br>
      <div class="inherit-width background-color u-border-radius u-padding-10 u-margin-bottom-30">
        <ul>
          <ng-container *ngFor="let item of listTrainingLevel; index as i">
            <li class="athlete-category-list-item u-capitalize" *ngIf="item?.is_default_level">{{ item.value }}</li>
          </ng-container>
        </ul>
      </div>
      <span class="u-font-size-14 u-font-weight-700 line-height-normal">Custom Categories</span>
      <hr>
      <br>
      <div class="o-flex o-flex--justify-space-between">
        <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
          <mat-label>Athlete Category 1</mat-label>
          <input matInput formControlName="custom1" />
          <mat-error *ngIf="form.controls?.custom1?.errors && form.controls?.custom1?.touched           ">
            <div [innerHTML]="form.controls.custom1.errors | errorFormFormat: { fieldName: 'Athlete Category 1' }"></div>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
          <mat-label>Athlete Category 2</mat-label>
          <input matInput formControlName="custom2" />
          <mat-error *ngIf="form.controls?.custom2?.errors && form.controls?.custom2?.touched">
            <div [innerHTML]="form.controls.custom2.errors | errorFormFormat: { fieldName: 'Athlete Category 2' }"></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="o-flex o-flex--justify-space-between">
        <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
          <mat-label>Athlete Category 3</mat-label>
          <input matInput formControlName="custom3" />
          <mat-error *ngIf="form.controls?.custom3?.errors && form.controls?.custom3?.touched">
            <div [innerHTML]="form.controls.custom3.errors | errorFormFormat: { fieldName: 'Athlete Category 3' }"></div>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
          <mat-label>Athlete Category 4</mat-label>
          <input matInput formControlName="custom4" />
          <mat-error *ngIf="form.controls?.custom4?.errors && form.controls?.custom4?.touched">
            <div [innerHTML]="form.controls.custom4.errors | errorFormFormat: { fieldName: 'Athlete Category 4' }"></div>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="o-flex o-flex--justify-space-between">
        <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
          <mat-label>Athlete Category 5</mat-label>
          <input matInput formControlName="custom5" />
          <mat-error *ngIf="form.controls?.custom5?.errors && form.controls?.custom5?.touched">
            <div [innerHTML]="form.controls.custom5.errors | errorFormFormat: { fieldName: 'Athlete Category 5' }"></div>
          </mat-error>
        </mat-form-field>
        <mat-form-field class="width-49-percent" appearance="outline" floatLabel="always">
          <mat-label>Athlete Category 6</mat-label>
          <input matInput formControlName="custom6" />
          <mat-error *ngIf="form.controls?.custom6?.errors && form.controls?.custom6?.touched">
            <div [innerHTML]="form.controls.custom6.errors | errorFormFormat: { fieldName: 'Athlete Category 6' }"></div>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <div class="full-width o-flex o-flex--justify-space-between u-margin-top-20">
      <button class="width-49-percent u-border-color" mat-button type="button" (click)="back()">Cancel</button>
      <button class="width-49-percent" mat-raised-button color="primary" (click)="save()">Save</button>
    </div>
  </mat-dialog-actions>
</div>
