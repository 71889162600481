import { inject, Injectable } from "@angular/core";
import { Observable, of, map, forkJoin } from "rxjs";
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { catchError } from "rxjs/operators";
import { AppConstants } from "@core/constants";
import { FeatureCategoryTypeEnum } from "@shared/enums/feature-category-type.enum";
import { FeatureStoreService } from "@shared/services/feature-store/feature-store.service";
import { AthletesService } from "@core/services/athletes-management/athletes.service";

@Injectable()
export class SportMetricVisualizationResolver {
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private customRendererService: CustomRendererService = inject(CustomRendererService);
  private featureStoreService: FeatureStoreService = inject(FeatureStoreService);
  private athletesService: AthletesService = inject(AthletesService);

  public resolve(): Observable<any> {
    const sportMetricTrainigLevelListObs$: Observable<any>[] = [
      this.featureStoreService.getFeatureListByCategory(FeatureCategoryTypeEnum.METRICS),
      this.featureStoreService.getExtendedSportFeatureConfigurationList(),
      this.athletesService.getAthleteTrainingLevel(),
    ];
    return forkJoin(sportMetricTrainigLevelListObs$)
      .pipe(
        map(([metric, sport, trainingLevel]: any) => ({
          metricList: metric,
          sportList: sport,
          trainingLevel: trainingLevel,
        })),
        catchError(error => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          return of(null);
        }
        )
      )
  }
}
