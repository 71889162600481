import { Component, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HelperService } from "@shared/services/helper.service";
import {
  logBuilderStatusWarnings
} from "@angular-devkit/build-angular/src/builders/browser-esbuild/builder-status-warnings";

@Component({
  selector: 'app-meter-configuration',
  templateUrl: './meter-configuration.component.html',
  styleUrls: ['./meter-configuration.component.scss']
})
export class MeterConfigurationComponent implements OnInit, OnChanges {
  private fb: FormBuilder = inject(FormBuilder);
  private helperService: HelperService = inject(HelperService);

  public selectMetricForm: FormGroup;
  public metricOptionList: any;
  public baseMetricOptionList: any;
  public outputResponse: any = {};

  @Input() public config: any;
  @Input() public metricOptionsConfig: any;
  @Input() public baseMeterOptionList: any;
  @Output() public meterConfigChanged: EventEmitter<any> = new EventEmitter<any>();

  public ngOnInit(): void {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('config')) {
      this.initForms();
      this.fillForms(changes.config.currentValue);
      this.outputResponse = this.helperService.makeCopy(changes.config.currentValue);
      this.prepareSportOptionList(this.metricOptionsConfig);
    }
  }

  public fillForms(config: any): void {
    this.selectMetricForm.patchValue({
      base: config.base.id,
      row1: config.row1?.id,
      row2: config.row2?.id,
      row3: config.row3?.id,
    });
  }

  public prepareSportOptionList(metricOptionList: any[]): void {
    this.baseMetricOptionList = metricOptionList.map((metric: any): any => ({
      id: metric.id,
      name: metric.name,
    })).filter((option: any) => option.id !== 'none');

    this.metricOptionList = metricOptionList.map((metric: any): any => ({
      id: metric.id,
      name: metric.name,
    }));
  }

  public initForms(): void {
    this.selectMetricForm = this.fb.group({
      base: [],
      row1: [],
      row2: [],
      row3: [],
    });
  }

  public onChangeBaseMetric(item: any): void {
    this.outputResponse['base'] = item;
    this.meterConfigChanged.emit(this.outputResponse);
  }

  public onChangeRow1(item: any): void {
    this.outputResponse['row1'] = item;
    this.meterConfigChanged.emit(this.outputResponse);
  }

  public onChangeRow2(item: any): void {
    this.outputResponse['row2'] = item;
    this.meterConfigChanged.emit(this.outputResponse);
  }

  public onChangeRow3(item: any): void {
    this.outputResponse['row3'] = item;
    this.meterConfigChanged.emit(this.outputResponse);
  }
}
