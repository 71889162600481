import { Injectable } from '@angular/core';
import { AppConstants } from '@core/constants';
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { FeatureFlags, Features } from '@shared/services/feature-flags/interfaces/feature-flags.interface';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public auth: any = {
    first_name: '',
    last_name: '',
    organization: {
      name: '',
    },
  };
  public rights: any = {};
  public features: Features = {};
  public isLoggedIn: boolean = false;

  constructor(private customRendererService: CustomRendererService) {}

  public setCookie(name: string, val: string): void {
    const date: Date = new Date();
    const value: string = val;

    // Set it expire in 7 days
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
    document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
  }

  public getCookie(name: string): string {
    const nameEQ: string = name + '=';
    const ca: string[] = document.cookie.split(';');
    for (let c of ca) {
      let item: string = c;
      while (item.charAt(0) === ' ') {
        item = item.substring(1, item.length);
      }
      if (item.indexOf(nameEQ) === 0) {
        return item.substring(nameEQ.length, c.length);
      }
    }
    return '';
  }

  public getCurrenAuthentication(): any {
    return JSON.parse(this.getCookie('auth'));
  }

  public deleteAllCookies(): void {
    let cookies: string[] = document.cookie.split(';');

    for (let i = 0; i < cookies.length; i++) {
      let cookie: string = cookies[i];
      let eqPos: number = cookie.indexOf('=');
      let name: string = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  public activateLoginStatus(): void {
    localStorage.setItem('isLoggedIn', 'true');
  }

  public deactivateLoginStatus(): void {
    localStorage.setItem('isLoggedIn', 'false');
  }

  public getCurrentUserProfile(): void {
    try {
      let auth: string = this.getCookie('auth');
      if (auth) {
        auth = JSON.parse(auth);
        this.auth = auth;
        this.isLoggedIn = true;

        const theme: string | null = localStorage.getItem('theme');
        let photoURL = theme === AppConstants.THEME.DARK
          ? "assets/images/app-logo-header-dark-theme.png"
          : "assets/images/app-logo-header.png";

        const orgPhoto = localStorage.getItem("orgPhoto");
        if (orgPhoto) {
          photoURL = orgPhoto.replace(
            'media/uploads',
            'dist'
          );
        }

        const logoContainer: Element | null = this.customRendererService.select('#current_login_logo');
        if (logoContainer) {
          this.customRendererService.setAttribute(logoContainer, 'src', photoURL);
        }
      }

      try {
        const rightsJson: string | null = localStorage.getItem('rights');
        if (rightsJson) {
          this.rights = JSON.parse(rightsJson);
        }
      } catch (error) {
        console.log('Cannot parse Rights', error);
      }

      try {
        this.features = this.getFeaturesFromSession();
      } catch (error) {
        console.log('Cannot parse features', error);
      }

    } catch (error) {
      this.isLoggedIn = false;
      console.log(error);
    }
  }

  private getFeaturesFromSession(): Features {
    const authCookieString: string = this.getCookie('auth' || '{}')
    const auth: Record<string, any> = JSON.parse(authCookieString);
    const features: Features = auth.features;
    return features ?? {};
  }
}
