import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from "@angular/core";
import { AppConstants } from "@core/constants";
import { FeatureStoreService } from "@shared/services/feature-store/feature-store.service";
import { ActivatedFeatureInterface, ActivatedFeatureInterfaceResponse } from "@shared/interfaces/activated-feature.interface";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import moment from "moment";
import { ConfirmDialogComponent, ConfirmDialogModel } from "@shared/dialogs/confirm-dialog/confirm-dialog.component";
import { MatDialogRef } from "@angular/material/dialog";
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-active-feature',
  templateUrl: './active-feature.component.html',
  styleUrls: ['./active-feature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveFeatureComponent implements OnInit {
  private featureStoreService: FeatureStoreService = inject(FeatureStoreService);
  private cdr: ChangeDetectorRef = inject(ChangeDetectorRef);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private customRendererService: CustomRendererService = inject(CustomRendererService);
  private readonly dialog: MatDialog = inject(MatDialog);

  public constant = AppConstants;
  public listActiveFeature: ActivatedFeatureInterface[] = [];

  public ngOnInit(): void {
    this.getFeatureList();
  }

  public getFeatureList(): void {
    this.featureStoreService.getActivatedFeatureList()
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (featureList: ActivatedFeatureInterfaceResponse): void => {
          this.listActiveFeature = featureList.results.map((feature: ActivatedFeatureInterface) => {
            return {
              ...feature,
              isShowDetail: false,
              period_type_clone: feature.period_type,
            };
          });
          this.cdr.markForCheck();

        },
        error: (error: string) => {
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
        }
      });
  }

  public cancelFeature(purchase: ActivatedFeatureInterface): void {
    const subscriptionEndDate = moment(purchase.expiration).utc().format(AppConstants.DATE_FORMAT.APP_FULL_DATE_MOMENT);
    const message = "You have requested to cancel your subscription to the " +
      `feature called '${purchase.name}'. The feature will be cancelled at the ` +
      `end of the current period, on ${subscriptionEndDate}. Until then you ` +
      "can still keep using the feature.";

    const dialogData: ConfirmDialogModel = new ConfirmDialogModel('Cancel feature subscription', message, 'Cancel Subscription', 'Keep Subscription', false);
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open(ConfirmDialogComponent, {
      width: '540px',
      data: dialogData,
      disableClose: false,
    });

    dialogRef.afterClosed()
      .subscribe((result: any): void => {
        if (!result) {
          // Keep subscription -> do nothing
          return
        }
        this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
        this.featureStoreService.unsubscribeFeature(purchase.id).subscribe({
          next: (response: any) => {
            this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
            const message = "Cancelled successfully. This feature will no longer auto-renew after the current period ends"
            this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
            setTimeout(() => {
              window.location.reload();
            }, 2500);
          },
          error: (error: any) => {
            this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
            let errorMessage = "Unable to unsubscribe. Please contact our support team for further assistance"
            if (error?.error?.detail) {
              errorMessage = error?.error?.detail as string
            }
            this.snackBar.open(errorMessage, 'OK', AppConstants.TOAST_CONFIG.ERROR);
          }
        });
      });
  }
}
