import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-preview-meter',
  templateUrl: './preview-meter.component.html',
  styleUrls: ['./preview-meter.component.scss']
})
export class PreviewMeterComponent {
  @Input() config: any;

  public getMetricName(metricRow: any): string {
    const metricRowName: string = metricRow.id !== "none" ? metricRow?.name : "";
    if (!metricRowName) {
      return ""
    }

    if (metricRowName.toLocaleLowerCase().includes("vlamax")) {
      return metricRowName.toLocaleLowerCase().replace("vlamax", "VLa<sub>max</sub>")
    }

    if (metricRowName.toLocaleLowerCase().includes("vo2max")) {
      return metricRowName.toLocaleLowerCase().replace("vo2max", "VO2<sub>max</sub>")
    }

    if (metricRowName.toLocaleLowerCase().includes("carbmax")) {
      return metricRowName.toLocaleLowerCase().replace("carbmax", "Carb<sub>max</sub>")
    }

    if (metricRowName.toLocaleLowerCase().includes("fatmax")) {
      return metricRowName.toLocaleLowerCase().replace("fatmax", "Fat<sub>max</sub>")
    }

    return metricRowName;
  }
}

