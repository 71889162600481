import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UrlBuilderService } from "@shared/services/url-builder.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { forkJoin, from, Observable } from "rxjs";
import { FeatureStoreService } from "@shared/services/feature-store/feature-store.service";
import { FormBuilder, FormGroup, UntypedFormArray, UntypedFormGroup, Validators } from "@angular/forms";
import { AppConstants } from "@core/constants";
import { AthletesService } from "@core/services/athletes-management/athletes.service";
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { SessionService } from "@core/services/session.service";
import { IEconomyEfficiencySettings, IEconomyEfficiencySettingsUnit } from "@shared/interfaces/economy-efficiency-settings.interface";
import { ECONOMY_EFFICIENCY_SETTINGS } from "@shared/constant-list/economy-efficiency-settings.constant";
import { HttpErrorResponse } from '@angular/common/http';
import { HelperService } from "@shared/services/helper.service";
import { DEFAULT_CUSTOM_ATHLETE_CATEGORY_LIST } from "@shared/constant-list/default-custom-athlete-category.constant";

@Component({
  selector: 'app-sport-configuration',
  templateUrl: './sport-configuration.component.html',
  styleUrls: ['./sport-configuration.component.scss']
})
export class SportConfigurationComponent implements OnInit {
  private urlBuilder: UrlBuilderService = inject(UrlBuilderService);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private featureStoreService: FeatureStoreService = inject(FeatureStoreService);
  private fb: FormBuilder = inject(FormBuilder);
  private athletesService: AthletesService = inject(AthletesService);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private sessionService: SessionService = inject(SessionService);
  private router: Router = inject(Router);
  private helperService: HelperService = inject(HelperService);

  public customUrl: string;
  public tabIndexToReturn: number = 0;
  public sportConfigurationForm: FormGroup;
  public referenceOptions: Array<any> = [];
  public secondaryReferenceOptions: Array<any> = [];
  public athleteCategoryOptions: any;
  public featureId: string
  public economyEfficientSettingsConfig: IEconomyEfficiencySettings[] = ECONOMY_EFFICIENCY_SETTINGS;
  public economyEfficientSettingsControl: IEconomyEfficiencySettings & IEconomyEfficiencySettingsUnit;
  public formConditions: UntypedFormGroup = {} as UntypedFormGroup;
  public params: any;
  public sportName: string;
  public isOwner: boolean;

  public ngOnInit(): void {
    this.initForm();
    this.subscribeToQueryParams();
    this.initCurrentPage();
    this.isOwner = this.sessionService.auth.role.is_owner;
  }

  public subscribeToQueryParams(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef),)
      .subscribe({
        next: (params: Params) => {
          this.params = params;
          this.sportName = this.params.feature_name;
        }
      });
  }

  public initCurrentPage(): void {
    let observableList: Observable<any>[] = [];

    if (this.params.configuration === 'null') {
      observableList =  [
        this.athletesService.getAthleteTrainingLevel(),
      ];
    } else {
      observableList =  [
        this.athletesService.getAthleteTrainingLevel(),
        this.featureStoreService.getFeatureConfigurationById(this.params.configuration),
      ];
    }

    forkJoin(observableList)
      .pipe(
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: ([athleteTrainingLevel, featureConfigurationData]: any): void => {
          this.setTabIndexToReturn(this.params.tab_index_to_return);
          this.setReferenceOption();
          this.customUrl = this.urlBuilder.getCreatedCustomUrl('/home/my-inscyd', 'tab_index_to_return', this.tabIndexToReturn);
          this.featureId = this.params.feature_id;

          if (athleteTrainingLevel) {
            this.athleteCategoryOptions = athleteTrainingLevel.items
              .filter((item: any) => !item.is_default_level && !DEFAULT_CUSTOM_ATHLETE_CATEGORY_LIST.includes(item.value))
              .map((item: any) => {
              return this.mappingOptionValue(item, 'value');
            });
          }

          if (featureConfigurationData) {
            let sportConfigurationObject: any = this.helperService.makeCopy(featureConfigurationData.data);
            let updatedSportConfigurationObject = this.updateCustomAthleteCategoryList(this.athleteCategoryOptions, sportConfigurationObject);

            this.fillSportConfigurationForm(updatedSportConfigurationObject);
            this.fillEconomyEfficientSettings();

            featureConfigurationData.data.conditions.forEach((condition: any) => {
              this.conditions.push(this.createNewConditions(condition));
            });

            this.updateCurrentCustomAthleteCategoryFormControl();
          }
        },
        error: (error: any) => {
          if (error !== 'Error: Resource not found or no permission.') {

          }
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        },
      });
  }

  public updateCurrentCustomAthleteCategoryFormControl(): void {
    const currentCustomList = this.sportConfigurationForm.get('custom_athlete_category')?.value;
    const customCategoryOptionList: string[] = this.athleteCategoryOptions.map((option: any) => option.name);
    const filteredCustomAthleteList = currentCustomList.filter((item: any) => customCategoryOptionList.includes(item.name));

    this.sportConfigurationForm.get('custom_athlete_category')?.setValue(filteredCustomAthleteList);
  }

  public removeConditionById(id: number, event: Event): void {
    event.stopPropagation();
    this.conditions.removeAt(id);
  }

  public updateEconomyEfficientSettings(event: any): void {
    this.sportConfigurationForm.get('economy_efficient_settings')?.setValue(event.item);
  }

  public initForm(): void {
    this.sportConfigurationForm = this.fb.group({
      primary_type: ['', Validators.required],
      secondary_type: ['', Validators.required],
      custom_athlete_category: [],
      economy_efficient_settings: [],
      default_name: [''],
      ede_factor_a: ['', Validators.required],
      ede_factor_b: ['', Validators.required],
      ede_factor_d: ['', Validators.required],
      per_watt_qw_a: ['', Validators.required],
      per_watt_qw_b: ['', Validators.required],
      per_watt_qw_d: ['', Validators.required],
      gross_efficiency: ['', {disabled: true}],
    });

    this.formConditions = this.fb.group({
      condition_name: ['', Validators.required],
      ede_factor_a: ['', Validators.required],
      ede_factor_b: ['', Validators.required],
      ede_factor_d: ['', Validators.required],
      per_watt_qw_eq_a: ['', Validators.required],
      per_watt_qw_eq_b: ['', Validators.required],
      per_watt_qw_eq_d: ['', Validators.required],
      gross_efficiency: ['', {disabled: true}],
      conditions: this.fb.array([]),
    });
  }

  public fillSportConfigurationForm(data: any): void {
    this.sportConfigurationForm.patchValue(data);
  }

  public updateCustomAthleteCategoryList(athleteTrainingLevel: any, sportConfigurationObject: any): any {
    sportConfigurationObject['custom_athlete_category'].forEach((category: any) => {
      const matchTrainingLevel: any = athleteTrainingLevel.find((trainingLevel: any) => trainingLevel. value === category.value);

      if (matchTrainingLevel) {
        category.name = matchTrainingLevel.name;
      }
    });

    return sportConfigurationObject;
  }

  public fillEconomyEfficientSettings(): void {
    if (this.sportConfigurationForm.get('economy_efficient_settings')?.value) {
      this.economyEfficientSettingsControl = this.sportConfigurationForm.get('economy_efficient_settings')?.value;
    }
  }

  public setReferenceOption(): void {
    this.referenceOptions = [
      ...AppConstants.REFERENCE_SYSTEMS_SPEED,
      ...AppConstants.REFERENCE_SYSTEMS_POWER,
    ];
    this.secondaryReferenceOptions = [
      { id: 0, name: 'None', unit: '', },
      ...AppConstants.REFERENCE_SYSTEMS_SPEED,
      ...AppConstants.REFERENCE_SYSTEMS_POWER,
    ];
  }

  private mappingOptionValue(item: any, param: string) {
    return {
      value: item?.id,
      name: item[param],
    };
  }

  public setTabIndexToReturn(returnIndex: number): void {
    this.tabIndexToReturn = returnIndex;
  }

  public back(): void {
    this.router.navigateByUrl(this.customUrl);
  }

  public save(): void {
    if (this.params.configuration !== 'null') {
      this.featureStoreService.updateFeatureConfiguration({ data: this.getSportConfigPayload() }, this.params.configuration)
        .subscribe({
          next: () => {
            this.snackBar.open('The configuration has been successfully updated', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          },
          error: (error: HttpErrorResponse) => {
            if (error.status === 400) {
              try {
                const message = error.error["detail"];
                return this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.ERROR);
              } catch (e) {
                console.error(e);
                return this.snackBar.open('The configuration has not been successfully updated', 'OK', AppConstants.TOAST_CONFIG.ERROR);
              }
            }
            return this.snackBar.open('The configuration has not been successfully updated', 'OK', AppConstants.TOAST_CONFIG.ERROR);
          },
        });
    } else {
      const payloadConfiguration: any = {
        data: this.getSportConfigPayload(),
        feature: +this.featureId,
        organization: JSON.parse(this.sessionService.getCookie('auth')).organization.id,
      };

      this.featureStoreService.createFeatureConfiguration(payloadConfiguration)
        .subscribe({
          next: (res: any) => {
            let queryParams: Record<string, null | number | undefined> = {
              tab_index_to_return: this.tabIndexToReturn,
              configuration: res.id,
              feature_id: +this.featureId
            };

            from(this.router.navigate(['/home/sport-configuration'], {queryParams}))
              .subscribe({
                next: (res: any) => {
                  this.params.configuration = res.id;
                }
              })
            this.snackBar.open('The configuration has been successfully created', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          },
          error: (error: HttpErrorResponse) => {
            if (error.status === 400) {
              try {
                const message = error.error["detail"];
                return this.snackBar.open(message, 'OK', AppConstants.TOAST_CONFIG.ERROR);
              } catch (e) {
                console.error(e);
                return this.snackBar.open('The configuration has not been successfully created', 'OK', AppConstants.TOAST_CONFIG.ERROR);
              }
            }
            return this.snackBar.open('The configuration has not been successfully created', 'OK', AppConstants.TOAST_CONFIG.ERROR);
          },
        });
    }
  }

  public getSportConfigPayload(): any {
    return { ...this.sportConfigurationForm.value, conditions: this.formConditions.get('conditions')?.value }
  }

  public onSelectCustomCategory(event: Event): void {
    this.sportConfigurationForm.get('custom_athlete_category')?.setValue(event);
  }

  public addCondition(): void {
    this.conditions.push(this.createNewConditions(this.formConditions.value));
  }

  public get conditions(): UntypedFormArray {
    return this.formConditions.get('conditions') as UntypedFormArray;
  }

  public createNewConditions(data: any): UntypedFormGroup {
    return this.fb.group({
      condition_name: [data.condition_name],
      ede_factor_a: [data.ede_factor_a],
      ede_factor_b: [data.ede_factor_b],
      ede_factor_d: [data.ede_factor_d],
      per_watt_qw_eq_a: [data.per_watt_qw_eq_a],
      per_watt_qw_eq_b: [data.per_watt_qw_eq_b],
      per_watt_qw_eq_d: [data.per_watt_qw_eq_d],
      gross_efficiency: [data.gross_efficiency, {disabled: true}],
    });
  }

  public getWattEqBValue(form: any): number {
    return form.controls?.per_watt_qw_b?.value ?? form.controls?.per_watt_qw_eq_b?.value;
  }
}
