import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { IPerformanceLimitsConfig } from "@shared/interfaces/performance-limits.interface";
import { VisualizationService } from "@shared/services/visualization.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { UrlBuilderService } from "@shared/services/url-builder.service";
import { IFeatureSport } from "@shared/interfaces/feature-sport.interface";
import { Observable } from "rxjs";
import { AppConstants } from "@core/constants";
import { FeatureStoreService } from "@shared/services/feature-store/feature-store.service";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";
import { testSettings } from "@shared/components/visualization/test-settings.constant";
import { metabolicQ } from "@shared/components/visualization/metaboli.contant";
import { default_text } from "@shared/components/visualization/default-text.constant";
import { ApiService } from "@core/services/api.service";
import { selected_test } from "@shared/components/visualization/selected-test.constant";
import { selected_athlete } from "@shared/components/visualization/selected-athlete.constant";
import { ChartConstants } from "@modules/apc/apc/data/chart-modules";
import { ChartMappingData } from "@modules/apc/apc/data/chart-mapping-data";
import { ChartService } from "@shared/services/chart.service";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { CustomRendererService } from "@shared/services/custom-renderer.service";
import { HelperService } from "@shared/services/helper.service";
import { MetricService } from "@shared/services/metric.service";
import { SIMULATION_TYPE } from "@shared/enums/simulation-type.enum";
import { String } from "typescript-string-operations";

@Component({
  selector: 'app-visualization',
  templateUrl: './visualization.component.html',
  styleUrls: ['./visualization.component.scss']
})
export class VisualizationComponent implements OnInit {
  private visualizationService: VisualizationService = inject(VisualizationService);
  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private destroyRef: DestroyRef = inject(DestroyRef);
  private urlBuilder: UrlBuilderService = inject(UrlBuilderService);
  private featureStoreService: FeatureStoreService = inject(FeatureStoreService);
  private snackBar: MatSnackBar = inject(MatSnackBar);
  private apiService: ApiService = inject(ApiService);
  private chartService: ChartService = inject(ChartService);
  private customRendererService: CustomRendererService = inject(CustomRendererService);
  private helperService: HelperService = inject(HelperService);
  private metricService: MetricService = inject(MetricService);
  private router: Router = inject(Router);

  public performanceLimitsConfig: IPerformanceLimitsConfig | any;
  public performanceLimitsConfigAngle1: IPerformanceLimitsConfig | any;
  public performanceLimitsConfigAngle2: IPerformanceLimitsConfig | any;
  public performanceLimitsConfigAngle3: IPerformanceLimitsConfig | any;
  public performanceLimitsObjectConfig: IPerformanceLimitsConfig | any;
  public performanceLimitsSavedData: any = [];
  public customAthleteCategoryList: any = [];
  public parentId: number;
  public leftButton1: string = 'L Axis 1';
  public enabledMetricList: any[] = [];
  public sportList: any = [];
  public metricList: any = [];
  public metricConfigurationId: number;
  public metricConfigListBySport: any = [];
  public currentMetricConfig: any;
  public simulationType: number;
  public isEnabledMetric: boolean = true;
  public defaultMeterConfig: any;
  public defaultConfigAngle: any[];
  public baseMeterOptionList: any = [];
  public dataPointNumber: number;
  public performanceDevelopmentButtonOptionList: any[] = [
    {
      name: 'Relative Watts',
      id: 1,
    },
    {
      name: 'Absolute Watts',
      id: 2,
    },
  ];
  public leftButton2: string = 'L Axis 2';
  public rightButton1: string = 'R Axis 1';

  public customUrl: string;
  public params: any;
  public tabIndexToReturn: number = 0;
  public currentSportId: number;
  public sportOptionList: any[] = [];
  public metricOptionList: IFeatureSport[] = [];
  public metricId: number;
  public metricName: string;
  public currentMetric: any;
  public isShowCurrentMetric: boolean = true;
  public previewMeterConfig: any[] = [];
  public meterOptionList: any;
  public angleOptionListBySportType: any;
  public currentMeterConfig: any;
  public currentMetabolicAngleConfig: any;
  public DEFAULT_CURRENT_METER_DATA_POINT: string = 'data_point-1';
  public currentMeterDataPoint: string = this.DEFAULT_CURRENT_METER_DATA_POINT;
  public PRIMARY_TYPE: string | undefined;
  public SECONDARY_TYPE: string | undefined;
  public referenceSystemOptions: any[] = [
    ...AppConstants.REFERENCE_SYSTEMS_SPEED,
    ...AppConstants.REFERENCE_SYSTEMS_POWER,
  ];

  public ngOnInit(): void {
    this.subscribeToQueryParams()
      .subscribe({
        next: (params: Params) => {
          this.params = params;
          this.metricId = +this.params.feature_id;

          this.setTabIndexToReturn(this.params.tab_index_to_return);
          this.setCustomUrl('/home/my-inscyd', 'tab_index_to_return', this.tabIndexToReturn);
          this.addMetricAndDescriptionToChildren();
          this.setMetricDropdownOption(this.activatedRoute.snapshot.data.data.metricList);
          this.setMetricName(this.metricId, this.activatedRoute.snapshot.data.data.metricList);
          this.setCurrentMetric(this.metricId, this.activatedRoute.snapshot.data.data.metricList);
          this.setMetricList(this.activatedRoute.snapshot.data.data.metricList);
          this.setEnabledMetricList(this.activatedRoute.snapshot.data.data.metricList);
          this.isEnabledMetric = this.isMetricEnabled(this.enabledMetricList, this.metricId);

          if (this.isEnabledMetric) {
            this.setCurrentSimulationType(this.activatedRoute.snapshot.data.data.sportList[0].feature.sport.simulation_type);

            if (this.activatedRoute.snapshot.data.data.sportList.length) {
              let mappedSportMetricList: any;

              if (this.helperService.isSpeedBasedSport(this.simulationType)) {
                this.defaultMeterConfig = this.helperService.makeCopy(this.metricService.getSpeedDropdownConfigByMetric(this.currentMetric));
                this.setReferenceTypeBySport(this.activatedRoute.snapshot.data.data.sportList[0].data);
                this.setReferenceValueToDropdownMenuItem(this.defaultMeterConfig);
                this.defaultConfigAngle = this.setDefaultMetabolicConfigAngle(this.currentMetric, this.activatedRoute.snapshot.data.data.sportList[0].feature.sport.simulation_type);
                this.meterOptionList = this.getDefaultMeterOptionListByConfig(this.defaultMeterConfig, this.metricName);
                this.setReferenceValueToDropdownMenuItem(this.defaultConfigAngle);
                this.angleOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultConfigAngle, this.metricName);
              } else if (this.helperService.isPowerBasedSport(this.simulationType)) {
                this.defaultConfigAngle = this.setDefaultMetabolicConfigAngle(this.currentMetric, this.activatedRoute.snapshot.data.data.sportList[0].feature.sport.simulation_type);
                this.defaultMeterConfig = this.metricService.getPowerDropdownConfigByMetric(this.currentMetric);
                this.meterOptionList = this.getDefaultMeterOptionListByConfig(this.defaultMeterConfig, this.metricName);
                this.setReferenceValueToDropdownMenuItem(this.defaultConfigAngle);
                this.angleOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultConfigAngle, this.metricName);
              }

              this.setCustomAthleteCategory(this.activatedRoute.snapshot.data.data.sportList[0].data.custom_athlete_category);
              this.updateCustomAthleteCategoryName(this.activatedRoute.snapshot.data.data.trainingLevel, this.customAthleteCategoryList);
              this.setCurrentSportId(this.activatedRoute.snapshot.data.data.sportList[0].feature.id);
              this.setMetricConfigId(this.metricId, this.activatedRoute.snapshot.data.data.sportList, this.currentSportId);
              this.setSportDropdownOption(this.activatedRoute.snapshot.data.data.sportList);
              this.setSportList(this.activatedRoute.snapshot.data.data.sportList);
              mappedSportMetricList = this.getMappedSportMetricById(this.currentSportId, this.sportList);
              mappedSportMetricList.forEach((metricConfig: any) => {
                this.addPerformanceLimitsSavedData(metricConfig, this.performanceLimitsSavedData, this.currentSportId);
              });
              this.initPreviewMeterConfig(this.enabledMetricList, this.currentSportId);
              this.setParentId(this.activatedRoute.snapshot.data.data.sportList[0].id);
              this.setCurrentPerformanceLimitsConfig(this.sportList, this.currentSportId, this.metricId, this.metricName, this.customAthleteCategoryList);
              this.initPreviewMetabolicAngleConfig(this.currentMetabolicAngleConfig);
            }
          }

        },
        error: (error: any) => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          this.snackBar.open(error, 'OK', AppConstants.TOAST_CONFIG.ERROR);
        },
      },);
  }

  public mapTrainingLevelList(athleteTrainingLevelList: any): any {
    let athleteCategoryList: any;

    if (athleteTrainingLevelList) {
      athleteCategoryList = athleteTrainingLevelList.items
        .filter((item: any) => !item.is_default_level)
        .map((item: any) => {
          return this.mappingTrainingLevel(item, 'value');
        });
    }

    return athleteCategoryList || [];
  }

  private mappingTrainingLevel(item: any, param: string) {
    return {
      value: item?.id,
      name: item[param],
    };
  }

  public updateCustomAthleteCategoryName(athleteTrainingLevelList: any, customAthleteCategory: any): any {
    const trainingLevelMappedList: any = this.mapTrainingLevelList(athleteTrainingLevelList);

    customAthleteCategory.forEach((category: any) => {
      const matchTrainingLevel: any = trainingLevelMappedList.find((trainingLevel: any) => trainingLevel. value === category.value);

      if (matchTrainingLevel) {
        category.name = matchTrainingLevel.name;
      }
    });

    return customAthleteCategory;
  }

  /**
   * This addMetricAndDescriptionToChildren method is used to add metricName and description properties
   * to each children object insight sportList array if there are no those properties,
   * I had to do it because some settings for specific metric have been set before I added new functionality,
   * that is why this method just adding metricName and description properties
   */
  public addMetricAndDescriptionToChildren(): void {
    this.activatedRoute.snapshot.data.data.sportList.forEach((item: any) => {
      item.children.forEach((children: any) => {
        let metricName: string = children.feature.name;
        for (let key in children.data.performance_limits) {
          const metricNameProperty: string = children.data.performance_limits[key].hasOwnProperty('metricName');
          const descriptionProperty: string = children.data.performance_limits[key].hasOwnProperty('description');
          if (!metricNameProperty || !descriptionProperty) {
            children.data.performance_limits[key].metricName = metricName;
            children.data.performance_limits[key].description = AppConstants.VISUALIZATION_PAGE.PERFORMANCE_LIMITS_DESCRIPTION;
          }
        }
      });
    });
  }

  public setReferenceValueToDropdownMenuItem(dropdownList: any): void {
    dropdownList.optionList.forEach((item: any) => {
      if (item.name.includes('{0}')) {
        item.name = String.Format(item.name, this.PRIMARY_TYPE);
      }
      if (item.name.includes('{1}')) {
        item.name = String.Format(item.name, undefined, this.SECONDARY_TYPE);
      }
    });
  }

  public setReferenceTypeBySport(data: any): void {
    this.referenceSystemOptions.forEach((reference: any) => {
      if (data.primary_type === reference.id) {
        this.PRIMARY_TYPE = reference.unit;
      }
      if (data.secondary_type === reference.id) {
        this.SECONDARY_TYPE = reference.unit;
      }
    });
  }

  public resetReferenceSystem(): void {
    this.PRIMARY_TYPE = undefined;
    this.SECONDARY_TYPE = undefined;
  }

  public setDefaultMetabolicConfigAngle(currentMetric: any, simulationType: number): any {
    if (simulationType === SIMULATION_TYPE.SPEED) {
      return this.metricService.getSpeedDropdownConfigByMetric(currentMetric);
    } else if (simulationType === SIMULATION_TYPE.POWER) {
      return this.metricService.getPowerDropdownConfigByMetric(currentMetric);
    }
  }

  public setCustomAthleteCategory(categoryList: any[]): void {
    this.customAthleteCategoryList = categoryList;
  }

  public getMappedSportMetricById(sportId: number, sportList: any[]): any {
    const currentSport: any = sportList.find((sport: any) => sport.feature.id === sportId);

    if (currentSport) {
      return currentSport.children.map((metric: any) => ({
        feature: metric.feature.id,
        data: metric.data,
        id: metric.id,
      }));
    }
  }

  public setCurrentSimulationType(type: number): void {
    this.simulationType = type;
  }

  public setCurrentMeterDataPoint(dataPoint: string): void {
    this.currentMeterDataPoint = dataPoint;
  }

  public setDefaultCurrentMeterDataPoint(dataPoint: string): void {
    this.currentMeterDataPoint = dataPoint;
  }

  public onMeterConfigChanged(data: any): void {
    const baseMeterDataPoint: string = data.base.id;
    this.setCurrentMeterDataPoint(baseMeterDataPoint);
    this.currentMeterConfig = data;

    this.previewMeterConfig.forEach((metric: any) => {
      if (metric.id === this.metricId) {
        metric.row1 = data.row1 !== 'none' ? data.row1 : false;
        metric.row2 = data.row2 !== 'none' ? data.row2 : false;
        metric.row3 = data.row3 !== 'none' ? data.row3 : false;
      }
    });

    if (this.performanceLimitsObjectConfig.hasOwnProperty(baseMeterDataPoint)) {
      this.performanceLimitsConfig = this.performanceLimitsObjectConfig[baseMeterDataPoint];
    }

    this.save()
  }

  public onMetabolicFingerprintChanged(data: any): void {
    this.currentMetabolicAngleConfig = data;
    if (this.performanceLimitsObjectConfig.hasOwnProperty(this.currentMetabolicAngleConfig.left.id)) {
      this.performanceLimitsConfigAngle1 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.left.id]);
    }
    if (this.performanceLimitsObjectConfig.hasOwnProperty(this.currentMetabolicAngleConfig.center.id)) {
      this.performanceLimitsConfigAngle2 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.center.id]);
    }
    if (this.performanceLimitsObjectConfig.hasOwnProperty(this.currentMetabolicAngleConfig.right.id)) {
      this.performanceLimitsConfigAngle3 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.right.id]);
    }

    this.save();
  }

  public setEnabledMetricList(allMetricList: any[]): void {
    this.enabledMetricList = allMetricList.filter((metric: any): any => {
      if (metric.is_enabled) {
        return metric;
      }
    });
  }

  public onTabChanged(event: MatTabChangeEvent): void {
    if (event.index === 2) {
      this.createPerformanceDevelopmentGraph(metabolicQ, default_text, testSettings);
    }  else {
      this.customRendererService.empty(ChartConstants.PERFORMANCE_DEVELOPMENT.ELEMENT_ID);
    }
  }

  public createPerformanceDevelopmentGraph(metabolicQ: any, default_text: any, testSettings: any): void {
    const response = this.apiService.parseTextResponse(metabolicQ);
    if (response) {
      const event: any = {
        id: 10,
        test_type: 'newtype',
        data: response,
        test: selected_test,
        athlete: selected_athlete,
        default_text: default_text,
        test_settings: testSettings,
        is_chart_changed: false,
      };
      const request = {
        data: event.data[4],
        testId: event.id,
        elementId: ChartConstants.PERFORMANCE_DEVELOPMENT.ELEMENT_ID,
        type: ChartConstants.PERFORMANCE_DEVELOPMENT.ID,
        test_type: event.test_type,
        legend: 'legends',
        mappingData: ChartMappingData,
      };

      setTimeout(() => {
        this.customRendererService.empty(ChartConstants.PERFORMANCE_DEVELOPMENT.ELEMENT_ID);
        this.chartService.ChartBokeh(request);
      })
    }
  }

  public onGraphLeftAxis1Changed(option: any): void {}

  public onGraphLeftAxis2Changed(option: any): void {}

  public onGraphRightAxis1Changed(option: any): void {}

  public setCurrentMetricVisibility(metricId: number, previewMetricList: any[]): void {
    // this.isShowCurrentMetric = previewMetricList.find((metric: any) => metric.id === metricId).visibility;
  }

  public toggleCurrentMetric(featureId: number): void {
    this.previewMeterConfig.forEach((meter: any) => {
      if (meter.id === featureId) {
        meter.visibility = !meter.visibility;
      }
    });
    this.isShowCurrentMetric = !this.isShowCurrentMetric;

    this.save();
  }

  public setMetricName(metricId: number, metricList: any[]): void {
    this.metricName = metricList.find((metric: any) => metric.id === metricId)?.name;
  }

  public setCurrentMetric(metricId: number, metricList: any): void {
    this.currentMetric = metricList.find((metric: any) => metric.id === metricId);
  }

  public setMetricList(metricList: any[]): void {
    this.metricList = metricList;
  }

  public setMetricConfigId(metricId: number, sportList: any[], currentSportId: number): void {
    const metricListBySport: any = this.getMetricListBySport(sportList, currentSportId)

    this.metricConfigurationId = metricListBySport.find((metric: any) => metric.feature.id === metricId)?.id;
  }

  public setMetricConfigurationBySport(metricId: number, children: any[]): void {
    this.metricConfigurationId = children.find((metric: any) => metric.feature.id === metricId)?.id;
  }

  public generateRowBasedOnMetricRowNumber(metric: any): any {
    let defaultMeterConfig: any;
    let rowList: any = {};
    let row1: any = {row1: {id: 'none', name: 'None',}};
    let row2: any = {row2: {id: 'none', name: 'None',}};
    let row3: any = {row3: {id: 'none', name: 'None',}};

    if (this.helperService.isSpeedBasedSport(this.simulationType)) {
      defaultMeterConfig = this.metricService.getSpeedDropdownConfigByMetric(metric);
    } else if (this.helperService.isPowerBasedSport(this.simulationType)) {
      defaultMeterConfig = this.metricService.getPowerDropdownConfigByMetric(metric);
    }

    if (defaultMeterConfig) {
      switch (defaultMeterConfig.rowNumber){
        case 1:
          return Object.assign(rowList, row1);
        case 2:
          return Object.assign(rowList, row1, row2);
        case 3:
          return Object.assign(rowList, row1, row2, row3);
        default:
          return {};
      }
    } else {
      return {};
    }
  }

  public initPreviewMeterConfig(enabledMetricList: any[], sportId: number): void {
    this.previewMeterConfig = enabledMetricList.map((metric: any) => {
      let defaultRowList: any = this.generateRowBasedOnMetricRowNumber(metric);
      let defaultMetricObject: any = {
        visibility: true,
        metricName: metric.name,
        id: metric.id,
        ...defaultRowList,
      };

      const hasMetric = this.performanceLimitsSavedData.find((savedSport: any) => savedSport.sportId === sportId && savedSport.metricId === metric.id);

      if (hasMetric) {
        let copyRowList = this.helperService.makeCopy(hasMetric);
        delete copyRowList.data.physiological_performance_benchmark.meter_configuration['base'];

        return {
          id: metric.id,
          visibility: hasMetric.data.physiological_performance_benchmark.metric_visibility,
          metricName: metric.name,
          ...copyRowList.data.physiological_performance_benchmark.meter_configuration,
        };
      } else {
        return defaultMetricObject;
      }
    });
  }

  public initPreviewMetabolicAngleConfig(metabolicAngleConfig: any): void {
    this.currentMetabolicAngleConfig = metabolicAngleConfig;
  }

  public setCustomUrl(url: string, param: string, tabIndex: number): void {
    this.customUrl = this.urlBuilder.getCreatedCustomUrl(url, param, tabIndex);
  }

  public setCurrentSportId(sportId: number): void {
    this.currentSportId = sportId;
  }

  public setSportDropdownOption(sportOptionList: any[]): void {
    this.sportOptionList = sportOptionList.map((sportItem: any): any => ({
      id: sportItem.feature.id,
      name: sportItem.feature.name,
      parent_id: sportItem.id,
    }));
  }

  public setMetricDropdownOption(metricOptionList: IFeatureSport[]): void {
    this.metricOptionList = metricOptionList;
  }

  public setSportList(sportList: any): void {
    this.sportList = sportList;
  }

  public getSportList(): any {
    return this.sportList;
  }

  public getMetricListBySport(sportList: any[], sportId: number): any {
    return sportList.find((sport: any) => sport.feature.id === sportId)?.children;
  }

  public setParentId(parentId: number): void {
    this.parentId = parentId;
  }

  public getDefaultBaseMeterConfig(metricName: string): any {
    return [{
      name: metricName,
      id: 'data_point-0',
    }];
  }

  public getDefaultMeterConfig(defaultMeterOptionListConfig: any): any {
    let finalConfig: any;

    if (defaultMeterOptionListConfig.rowNumber === 0) {
      finalConfig = { base: { id: 'data_point-0'}, };
    } else {
      finalConfig = { base: { id: 'data_point-1', }, };
    }

    const row1: any = { row1: { id: 'none', } };
    const row2: any = { row2: { id: 'none', } };
    const row3: any = { row3: { id: 'none', } };

    switch(defaultMeterOptionListConfig.rowNumber) {
      case 1:
        return Object.assign(finalConfig, row1);
      case 2:
        return Object.assign(finalConfig, row1, row2);
      case 3:
        return Object.assign(finalConfig, row1, row2, row3);
      default:
        return finalConfig;
    }
  }

  public getDefaultMetabolicAngleConfig(): any {
    return {
      left: {
        id: 'none',
        name: 'None',
      },
      center: {
        id: 'none',
        name: 'None',
      },
      right: {
        id: 'none',
        name: 'None',
      },
    };
  }

  public getDefaultMeterOptionListByConfig(config: any, metricName: string): any[] {
    const baseMetricName: any = {
      name: metricName,
      id: 'data_point-0',
    };

    if (config.rowNumber === 0) {
      return [
        {
          name: 'None',
          id: 'none',
        },
        baseMetricName,
      ]
    } else {
      return [
        {
          name: 'None',
          id: 'none',
        },
        ...config.optionList,
      ];
    }
  }

  public getCurrentMetricVisibility(): boolean {
    return true;
  }

  public updateCustomAthleteCategoryList(selectedMeterOption: string, performanceLimitsList: any, customAthleteIdList: number[], customAthleteCategoryList: any): any {
    let performanceLimitsListCopy: any = performanceLimitsList[selectedMeterOption];
    let customAthleteIdListCopy: any = customAthleteIdList;
    let customAthleteCategoryListCopy: any = customAthleteCategoryList;

    for (let custom in performanceLimitsListCopy.custom_tab_object) {
      if (!customAthleteIdListCopy.includes(performanceLimitsListCopy.custom_tab_object[custom].id)) {
        delete performanceLimitsListCopy.custom_tab_object[custom];
      }
    }

    const updatedCustomAthleteCategoryObject = this.helperService.makeCopy(performanceLimitsListCopy.custom_tab_object);

    performanceLimitsListCopy.custom_tab_object = {
      ...updatedCustomAthleteCategoryObject
    };

    const customAthleteCategoryFromTrainingLevelList = this.visualizationService.generateCustomAthleteCategoryObject(customAthleteCategoryListCopy);

    const arrayKey: any[] = [];

    for (let custom in performanceLimitsListCopy.custom_tab_object) {
      for (let customNew in customAthleteCategoryFromTrainingLevelList) {
        if (performanceLimitsListCopy.custom_tab_object[custom].id === customAthleteCategoryFromTrainingLevelList[customNew].id) {
          arrayKey.push(customNew);
          performanceLimitsListCopy.custom_tab_object[custom].name = customAthleteCategoryFromTrainingLevelList[customNew].name;
        }
      }
    }

    arrayKey.forEach((key: string) => {
      delete customAthleteCategoryFromTrainingLevelList[key];
    });


    performanceLimitsListCopy.custom_tab_object = {
      ...performanceLimitsListCopy.custom_tab_object,
      ...customAthleteCategoryFromTrainingLevelList,
    };

    return performanceLimitsListCopy;
  }

  public setCurrentPerformanceLimitsConfig(sportList: any, currentSportId: number, metricId: number, metricName: string, customAthleteCategoryList: any[]): void {
    this.metricConfigListBySport = this.getMetricListBySport(sportList, currentSportId);
    this.currentMetricConfig = this.metricConfigListBySport.find((metric: any): any => metric.feature.id === metricId);
    this.resetBaseMeterOptionListConfig();

     if (this.currentMetricConfig) {
       let performanceLimitsList: any = this.currentMetricConfig.data.performance_limits;
       let currentMeterConfig: any = this.currentMetricConfig.data.physiological_performance_benchmark.meter_configuration;
       let currentMeterVisibility: any = this.currentMetricConfig.data.physiological_performance_benchmark.metric_visibility;
       let currentMetabolicAngleConfig: any = this.currentMetricConfig.data.metabolic_fingerprint.current_metabolic_angle_config;
       let customAthleteIdList: number[] = customAthleteCategoryList.map((custom: any) => custom.value);

       this.currentMeterConfig = this.getDefaultMeterConfig(this.defaultMeterConfig);
       this.setCurrentMeterDataPoint(this.currentMeterConfig.base.id);

       if (!this.currentMetricConfig.data.physiological_performance_benchmark.hasOwnProperty('metric_visibility')) {
         currentMeterVisibility = this.getCurrentMetricVisibility();
       }

       if (!this.currentMetricConfig.data.physiological_performance_benchmark.hasOwnProperty('meter_configuration')) {
         currentMeterConfig = this.getDefaultMeterConfig(this.defaultMeterConfig);
       }

       if (!this.currentMetricConfig.data.metabolic_fingerprint.hasOwnProperty('current_metabolic_angle_config')) {
         currentMetabolicAngleConfig = this.getDefaultMeterConfig(this.defaultMeterConfig);
       }

       if (!this.currentMetricConfig.data.hasOwnProperty('performance_limits')) {
         performanceLimitsList = this.generateDefaultPerformanceLimitsObjectConfig(metricName, customAthleteCategoryList);
       }

       const performanceLimitsKeyList: string[] = Object.keys(performanceLimitsList);

       performanceLimitsKeyList.forEach((key: string) => {
         this.updateCustomAthleteCategoryList(key, performanceLimitsList, customAthleteIdList, customAthleteCategoryList);
       });

       this.performanceLimitsObjectConfig = performanceLimitsList;
       this.performanceLimitsConfig = this.performanceLimitsObjectConfig[this.currentMeterDataPoint];

       if (this.performanceLimitsObjectConfig[this.currentMeterDataPoint]) {
         this.performanceLimitsConfigAngle1 = this.performanceLimitsObjectConfig[this.currentMeterDataPoint];
         this.performanceLimitsConfigAngle2 = this.performanceLimitsObjectConfig[this.currentMeterDataPoint];
         this.performanceLimitsConfigAngle3 = this.performanceLimitsObjectConfig[this.currentMeterDataPoint];
       }


       this.currentMeterConfig = currentMeterConfig;
       if (this.performanceLimitsObjectConfig.hasOwnProperty(this.currentMeterConfig.base.id)) {
         this.performanceLimitsConfig = this.performanceLimitsObjectConfig[this.currentMeterConfig.base.id];
       }
       if (this.defaultMeterConfig.rowNumber === 0) {
         this.baseMeterOptionList = this.getDefaultBaseMeterConfig(this.metricName);
       }

       this.currentMetabolicAngleConfig = currentMetabolicAngleConfig;
       this.setPerformanceLimitsAngles(this.currentMetabolicAngleConfig);
       this.isShowCurrentMetric = currentMeterVisibility;
       this.dataPointNumber = this.defaultMeterConfig.rowNumber;
     } else {
       this.currentMeterConfig = this.getDefaultMeterConfig(this.defaultMeterConfig);
       this.setCurrentMeterDataPoint(this.currentMeterConfig.base.id);
       if (this.defaultMeterConfig.rowNumber === 0) {
         this.baseMeterOptionList = this.getDefaultBaseMeterConfig(this.metricName);
       }

       this.currentMetabolicAngleConfig = this.getDefaultMetabolicAngleConfig();
       this.isShowCurrentMetric = this.getCurrentMetricVisibility();
       this.performanceLimitsObjectConfig = this.generateDefaultPerformanceLimitsObjectConfig(metricName, customAthleteCategoryList);
       this.setPerformanceLimitsAngles(this.currentMetabolicAngleConfig);
       this.performanceLimitsConfig = this.performanceLimitsObjectConfig[this.currentMeterDataPoint];
       this.dataPointNumber = this.defaultMeterConfig.rowNumber;
     }
  }

  public generateDefaultPerformanceLimitsObjectConfig(metricName: string, customAthleteCategoryList: any[]): any {
    const dataPoint0: string = 'data_point-0';
    const dataPoint1: string = 'data_point-1';
    const dataPoint2: string = 'data_point-2';
    const dataPoint3: string = 'data_point-3';

    if (this.defaultMeterConfig.optionList.length === 0) {
      return {
        [dataPoint0]: {
          ...this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList)),
        },
      };
    } else {
      switch(this.defaultMeterConfig.optionList.length) {
        case 1:
          return {
            [dataPoint1]: {
              ...this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList)),
            },
          };
        case 2:
          return {
            [dataPoint1]: {
              ...this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList)),
            },
            [dataPoint2]: {
              ...this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList)),
            },
          };
        case 3:
          return {
            [dataPoint1]: {
              ...this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList)),
            },
            [dataPoint2]: {
              ...this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList)),
            },
            [dataPoint3]: {
              ...this.helperService.makeCopy(this.visualizationService.getDefaultPerformanceLimitsTabConfig(metricName, customAthleteCategoryList)),
            },
          };
      }
    }
  }

  public resetBaseMeterOptionListConfig(): void {
    this.baseMeterOptionList = [];
  }

  public setTabIndexToReturn(returnIndex: number): void {
    this.tabIndexToReturn = returnIndex;
  }

  public subscribeToQueryParams(): Observable<any> {
    return this.activatedRoute.queryParams.pipe(takeUntilDestroyed(this.destroyRef));
  }

  public isMetricEnabled(enabledMetricList: any[], currentMetricId: number): boolean {
    return enabledMetricList.some((metric: any) => metric.id === currentMetricId);
  }

  public onPerformanceLimitsChanged(newConfig: any): void {
    this.performanceLimitsObjectConfig[this.currentMeterConfig.base.id].custom_tab_object = newConfig.custom_tab_object;

    if (this.currentMetabolicAngleConfig.left.id === this.currentMeterConfig.base.id) {
      this.performanceLimitsConfigAngle1 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMeterConfig.base.id]);
    }

    if (this.currentMetabolicAngleConfig.center.id === this.currentMeterConfig.base.id) {
      this.performanceLimitsConfigAngle2 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMeterConfig.base.id]);
    }

    if (this.currentMetabolicAngleConfig.right.id === this.currentMeterConfig.base.id) {
      this.performanceLimitsConfigAngle3 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMeterConfig.base.id]);
    }

    if (this.helperService.isSpeedBasedSport(this.simulationType) && AppConstants.LINKED_SPORT_PERFORMANCE_LIMITS.includes(this.currentMetric.slug)) {
      // Update all data points to the same values
      for (const dataPoint in this.performanceLimitsObjectConfig) {
        this.performanceLimitsObjectConfig[dataPoint].custom_tab_object = newConfig.custom_tab_object
      }

      if (this.currentMetabolicAngleConfig.left.id !== "none") {
        this.performanceLimitsConfigAngle1 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMeterConfig.base.id]);
      }

      if (this.currentMetabolicAngleConfig.center.id !== "none") {
        this.performanceLimitsConfigAngle2 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMeterConfig.base.id]);
      }

      if (this.currentMetabolicAngleConfig.right.id !== "none") {
        this.performanceLimitsConfigAngle3 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMeterConfig.base.id]);
      }
    }

    this.save();
  }

  public onPerformanceLimitsChangedAngle1(newConfig: any): void {
    this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.left.id].custom_tab_object = newConfig.custom_tab_object;

    if (this.currentMeterConfig.base.id === this.currentMetabolicAngleConfig.left.id) {
      this.performanceLimitsConfig = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMeterConfig.base.id]);
    }
    if (this.currentMetabolicAngleConfig.center.id === this.currentMetabolicAngleConfig.left.id) {
      this.performanceLimitsConfigAngle2 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.left.id]);
    }

    if (this.currentMetabolicAngleConfig.right.id === this.currentMetabolicAngleConfig.left.id) {
      this.performanceLimitsConfigAngle3 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.left.id]);
    }

    if (this.helperService.isSpeedBasedSport(this.simulationType) && AppConstants.LINKED_SPORT_PERFORMANCE_LIMITS.includes(this.currentMetric.slug)) {
      // Update all data points to the same values
      for (const dataPoint in this.performanceLimitsObjectConfig) {
        this.performanceLimitsObjectConfig[dataPoint].custom_tab_object = newConfig.custom_tab_object
      }

      if (this.currentMeterConfig.base.id !== "none") {
        this.performanceLimitsConfig = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.left.id]);
      }

      if (this.currentMetabolicAngleConfig.center.id !== "none") {
        this.performanceLimitsConfigAngle2 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.left.id]);
      }

      if (this.currentMetabolicAngleConfig.right.id !== "none") {
        this.performanceLimitsConfigAngle3 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.left.id]);
      }
    }

    this.save();
  }

  public onPerformanceLimitsChangedAngle2(newConfig: any): void {
    this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.center.id].custom_tab_object = newConfig.custom_tab_object;

    if (this.currentMeterConfig.base.id === this.currentMetabolicAngleConfig.center.id) {
      this.performanceLimitsConfig = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMeterConfig.base.id]);
    }
    if (this.currentMetabolicAngleConfig.left.id === this.currentMetabolicAngleConfig.center.id) {
      this.performanceLimitsConfigAngle1 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.center.id]);
    }

    if (this.currentMetabolicAngleConfig.right.id === this.currentMetabolicAngleConfig.center.id) {
      this.performanceLimitsConfigAngle3 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.center.id]);
    }

    if (this.helperService.isSpeedBasedSport(this.simulationType) && AppConstants.LINKED_SPORT_PERFORMANCE_LIMITS.includes(this.currentMetric.slug)) {
      // Update all data points to the same values
      for (const dataPoint in this.performanceLimitsObjectConfig) {
        this.performanceLimitsObjectConfig[dataPoint].custom_tab_object = newConfig.custom_tab_object
      }

      if (this.currentMeterConfig.base.id !== "none") {
        this.performanceLimitsConfig = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.center.id]);
      }

      if (this.currentMetabolicAngleConfig.left.id !== "none") {
        this.performanceLimitsConfigAngle1 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.center.id]);
      }

      if (this.currentMetabolicAngleConfig.right.id !== "none") {
        this.performanceLimitsConfigAngle3 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.center.id]);
      }
    }

    this.save();
  }

  public onPerformanceLimitsChangedAngle3(newConfig: any): void {
    this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.right.id].custom_tab_object = newConfig.custom_tab_object;

    if (this.currentMeterConfig.base.id === this.currentMetabolicAngleConfig.center.id) {
      this.performanceLimitsConfig = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMeterConfig.base.id]);
    }
    if (this.currentMetabolicAngleConfig.left.id === this.currentMetabolicAngleConfig.right.id) {
      this.performanceLimitsConfigAngle1 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.right.id]);
    }

    if (this.currentMetabolicAngleConfig.center.id === this.currentMetabolicAngleConfig.right.id) {
      this.performanceLimitsConfigAngle2 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.right.id]);
    }

    if (this.helperService.isSpeedBasedSport(this.simulationType) && AppConstants.LINKED_SPORT_PERFORMANCE_LIMITS.includes(this.currentMetric.slug)) {
      // Update all data points to the same values
      for (const dataPoint in this.performanceLimitsObjectConfig) {
        this.performanceLimitsObjectConfig[dataPoint].custom_tab_object = newConfig.custom_tab_object
      }

      if (this.currentMeterConfig.base.id !== "none") {
        this.performanceLimitsConfig = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.right.id]);
      }

      if (this.currentMetabolicAngleConfig.left.id !== "none") {
        this.performanceLimitsConfigAngle1 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.right.id]);
      }

      if (this.currentMetabolicAngleConfig.center.id !== "none") {
        this.performanceLimitsConfigAngle2 = this.helperService.makeCopy(this.performanceLimitsObjectConfig[this.currentMetabolicAngleConfig.right.id]);
      }
    }

    this.save();
  }

  public onSportChanged(config: any): void {
    this.featureStoreService.getExtendedSportFeatureConfigurationList()
      .subscribe({
        next: (sportList: any) => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          const currentSport: any = sportList.find((sport: any) => sport.feature.id === config.sportId);
          let mappedSportMetricList: any;

          this.resetReferenceSystem();
          this.setCurrentSimulationType(currentSport.feature.sport?.simulation_type);
          if (this.helperService.isSpeedBasedSport(this.simulationType)) {
            this.defaultMeterConfig = this.helperService.makeCopy(this.metricService.getSpeedDropdownConfigByMetric(this.currentMetric));
            this.setReferenceTypeBySport(currentSport.data);
            this.setReferenceValueToDropdownMenuItem(this.defaultMeterConfig);
            this.meterOptionList = this.getDefaultMeterOptionListByConfig(this.defaultMeterConfig, this.metricName);
            this.defaultConfigAngle = this.setDefaultMetabolicConfigAngle(this.currentMetric, currentSport.feature.sport?.simulation_type);
            this.setReferenceValueToDropdownMenuItem(this.defaultConfigAngle);
            this.angleOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultConfigAngle, this.metricName);
          } else if (this.helperService.isPowerBasedSport(this.simulationType)) {
            this.defaultMeterConfig = this.metricService.getPowerDropdownConfigByMetric(this.currentMetric);
            this.meterOptionList = this.getDefaultMeterOptionListByConfig(this.defaultMeterConfig, this.metricName);
            this.defaultConfigAngle = this.setDefaultMetabolicConfigAngle(this.currentMetric, currentSport.feature.sport?.simulation_type);
            this.setReferenceValueToDropdownMenuItem(this.defaultConfigAngle);
            this.angleOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultConfigAngle, this.metricName);
          }
          this.setCustomAthleteCategory(currentSport.data.custom_athlete_category);
          this.updateCustomAthleteCategoryName(this.activatedRoute.snapshot.data.data.trainingLevel, this.customAthleteCategoryList);
          this.setCurrentSportId(config.sportId);
          this.setMetricConfigurationBySport(this.metricId, currentSport.children);
          mappedSportMetricList = this.getMappedSportMetricById(this.currentSportId, this.sportList);
          mappedSportMetricList.forEach((metricConfig: any) => {
            this.addPerformanceLimitsSavedData(metricConfig, this.performanceLimitsSavedData, this.currentSportId);
          });
          this.setParentId(currentSport.id);
          this.initPreviewMeterConfig(this.enabledMetricList, this.currentSportId);
          this.setCurrentPerformanceLimitsConfig(sportList, config.sportId, this.metricId, this.metricName, this.customAthleteCategoryList);
        },
        error: () => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
        }
      })
  }

  public onMetricChanged(metric: any): void {
    const hasMetricInSavedArray: any = this.performanceLimitsSavedData.find((sport: any) => sport.sportId === this.currentSportId && sport.metricId === metric.id);
    this.setCurrentMetric(metric.id, this.activatedRoute.snapshot.data.data.metricList);

    if (hasMetricInSavedArray) {
      this.metricConfigurationId = hasMetricInSavedArray.id;
    }

    this.metricName = metric.name;
    this.metricId = metric.id;
    this.setCurrentMetricVisibility(this.metricId, this.previewMeterConfig);
    this.isEnabledMetric = this.isMetricEnabled(this.enabledMetricList, this.metricId);

    if (this.isEnabledMetric) {
      if (this.helperService.isSpeedBasedSport(this.simulationType)) {
        this.defaultMeterConfig = this.helperService.makeCopy(this.metricService.getSpeedDropdownConfigByMetric(this.currentMetric));
        this.setReferenceValueToDropdownMenuItem(this.defaultMeterConfig);
        this.meterOptionList = this.getDefaultMeterOptionListByConfig(this.defaultMeterConfig, this.metricName);
        this.defaultConfigAngle = this.setDefaultMetabolicConfigAngle(this.currentMetric, this.simulationType);
        this.setReferenceValueToDropdownMenuItem(this.defaultConfigAngle);
        this.angleOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultConfigAngle, this.metricName);
      } else if (this.helperService.isPowerBasedSport(this.simulationType)) {
        this.defaultConfigAngle = this.setDefaultMetabolicConfigAngle(this.currentMetric, this.simulationType);
        this.defaultMeterConfig = this.metricService.getPowerDropdownConfigByMetric(this.currentMetric);
        this.meterOptionList = this.getDefaultMeterOptionListByConfig(this.defaultMeterConfig, this.metricName);
        this.setReferenceValueToDropdownMenuItem(this.defaultConfigAngle);
        this.angleOptionListBySportType = this.getDefaultMeterOptionListByConfig(this.defaultConfigAngle, this.metricName);
      }

      if (hasMetricInSavedArray) {
        let performanceLimitsList: any = hasMetricInSavedArray.data.performance_limits;
        let currentMeterConfig: any = hasMetricInSavedArray.data.physiological_performance_benchmark.meter_configuration;
        let currentMeterVisibility: any = hasMetricInSavedArray.data.physiological_performance_benchmark.metric_visibility;
        let currentMetabolicAngleConfig: any = hasMetricInSavedArray.data.metabolic_fingerprint.current_metabolic_angle_config;

        let customAthleteIdList: number[] = this.customAthleteCategoryList.map((custom: any) => custom.value);

        if (!hasMetricInSavedArray.data.physiological_performance_benchmark.hasOwnProperty('metric_visibility')) {
          currentMeterVisibility = this.getCurrentMetricVisibility();
        }

        if (!hasMetricInSavedArray.data.physiological_performance_benchmark.hasOwnProperty('meter_configuration')) {
          currentMeterConfig = this.getDefaultMeterConfig(this.defaultMeterConfig);
        }

        if (!hasMetricInSavedArray.data.metabolic_fingerprint.hasOwnProperty('current_metabolic_angle_config')) {
          currentMetabolicAngleConfig = this.getDefaultMeterConfig(this.defaultMeterConfig);
        }

        if (!hasMetricInSavedArray.data.hasOwnProperty('performance_limits')) {
          performanceLimitsList = this.generateDefaultPerformanceLimitsObjectConfig(this.metricName, this.customAthleteCategoryList);
        }

        this.currentMeterConfig = currentMeterConfig;
        this.setCurrentMeterDataPoint(this.currentMeterConfig.base.id);

        const performanceLimitsKeyList: string[] = Object.keys(performanceLimitsList);

        performanceLimitsKeyList.forEach((key: string) => {
          this.updateCustomAthleteCategoryList(key, performanceLimitsList, customAthleteIdList, this.customAthleteCategoryList);
        });

        this.performanceLimitsObjectConfig = performanceLimitsList;
        this.performanceLimitsConfig = this.performanceLimitsObjectConfig[this.currentMeterDataPoint];
        this.currentMetabolicAngleConfig = currentMetabolicAngleConfig;
        this.setPerformanceLimitsAngles(this.currentMetabolicAngleConfig);
        this.baseMeterOptionList = this.getDefaultBaseMeterConfig(this.metricName);
        this.isShowCurrentMetric = currentMeterVisibility;
        this.dataPointNumber = this.defaultMeterConfig.rowNumber;
      } else {
        this.setCurrentPerformanceLimitsConfig(this.sportList, this.currentSportId, this.metricId, this.metricName, this.customAthleteCategoryList);
      }
    }
  }

  public setPerformanceLimitsAngles(currentMetabolicAngleConfig: any): void {
    if (currentMetabolicAngleConfig.hasOwnProperty('left')) {
      this.performanceLimitsConfigAngle1 = this.performanceLimitsObjectConfig[currentMetabolicAngleConfig.left.id];
    }
    if (currentMetabolicAngleConfig.hasOwnProperty('center')) {
      this.performanceLimitsConfigAngle2 = this.performanceLimitsObjectConfig[currentMetabolicAngleConfig.center.id];
    }
    if (currentMetabolicAngleConfig.hasOwnProperty('right')) {
      this.performanceLimitsConfigAngle3 = this.performanceLimitsObjectConfig[currentMetabolicAngleConfig.right.id];
    }
  }

  public getMetricConfigPayload(): any {
    return {
      performance_limits: this.performanceLimitsObjectConfig,
      physiological_performance_benchmark: {
        meter_configuration: this.currentMeterConfig,
        metric_visibility: this.isShowCurrentMetric,
      },
      metabolic_fingerprint: {
        current_metabolic_angle_config: this.currentMetabolicAngleConfig,
      },
    };
  }

  public  addPerformanceLimitsSavedData(metric: any, performanceLimitsSavedData: any[], sportId: number): void {
    const sport: any = performanceLimitsSavedData.find((sport: any) => sport.sportId === sportId && sport.metricId === metric.feature);

    if (sport) {
      sport.data = metric.data;
    } else {
      performanceLimitsSavedData.push({
        sportId: sportId,
        metricId: metric.feature,
        data: metric.data,
        id: metric.id,
      });
    }
  }

  public save(): void {
    const payloadMetricConfiguration: any = {
      data: this.getMetricConfigPayload(),
      feature: +this.metricId,
      parent: this.parentId,
    };
    const currentSport: any = this.performanceLimitsSavedData.find((sport: any) => sport.sportId === this.currentSportId && sport.metricId === this.metricId);

    if (currentSport) {
      this.featureStoreService.updateFeatureConfiguration(payloadMetricConfiguration, this.metricConfigurationId)
        .subscribe({
          next: (res: any) => {
            this.addPerformanceLimitsSavedData(res, this.performanceLimitsSavedData, this.currentSportId);
            this.snackBar.open('The configuration has been successfully updated', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          },
          error: () => {
            this.snackBar.open('The configuration has not been successfully created', 'OK', AppConstants.TOAST_CONFIG.ERROR);
          },
        });
    } else {
      this.featureStoreService.createFeatureConfiguration(payloadMetricConfiguration)
        .subscribe({
          next: (res: any) => {
            this.metricConfigurationId = res.id;
            this.addPerformanceLimitsSavedData(res, this.performanceLimitsSavedData, this.currentSportId);
            this.snackBar.open('The configuration has been successfully updated', 'OK', AppConstants.TOAST_CONFIG.SUCCESS);
          },
          error: () => {
            this.snackBar.open('The configuration has not been successfully created', 'OK', AppConstants.TOAST_CONFIG.ERROR);
          },
        });
    }
  }

  public purchaseMetric(): void {
    if (this.currentMetric?.slug) {
      this.router.navigate(['home/feature-store', 'feature-detail'], {
        queryParams: {
          feature_slug: this.currentMetric.slug,
          tab_index_to_return: 0
        }
      });
    } else {
      this.router.navigate(['/home/feature-store/catalog']);
    }
  }

  /**
   * Based on the sport/metric and current base data point we determine the
   * reference system for the performance limit component. This reference system
   * is used to convert from m/s to pace and vice versa for speed based sports
   * that use pace values
   */
  public getReferenceSystemId(name: string): number | undefined {
    if (!name) return undefined;

    // Speed sports only
    if (this.simulationType !== SIMULATION_TYPE.SPEED) {
      return undefined
    }

    // The name has to contain units
    const containsUnits = (name.indexOf('[') > -1 && name.indexOf(']') > -1);
    if (!containsUnits) {
      return undefined
    }

    const baseUnit = name.substring(name.indexOf('[') + 1, name.indexOf(']'))

    // Find the reference system object that matches the unit
    const referenceSystemUnit = this.referenceSystemOptions.find(option => option.unit === baseUnit);
    if (!referenceSystemUnit) {
      return undefined
    }

    return referenceSystemUnit.id;
  }
}
