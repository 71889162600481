<div class="tile" [class.tile--recommended-tile]="config.isRecommended">
  <div class="title__header">
    <div class="tile__hover-box-with-button">
      <button (click)="navigateTo()" class="tile--center-of-box" mat-raised-button color="primary">{{config.buttonName}}</button>
    </div>
    <img class="tile__image" [src]="config.image" [alt]="config.title">
  </div>
  <div class="tile__title-label tile--padding-left-right">
    <div class="u-font-size-20 u-font-weight-500 u-ellipsis-general u-ellipsis-1-line">{{config.title}}</div>
    <ng-container *ngIf="config.tags.length">
      <app-label class="u-right-padding-5" *ngFor="let label of config.tags" [text]="label" [isRecommended]="config.isRecommended" [isBeta]="isBeta()" [labelHeight]="config.tagHeight"></app-label>
    </ng-container>
  </div>
  <div class="tile__description tile--padding-left-right u-ellipsis-general u-ellipsis-5-line">{{config.description}}</div>
</div>
