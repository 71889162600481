<div class="preview-meter u-border-radius border-color tile-color-background u-padding-20">
  <ng-container *ngFor="let metric of config">
    <div class="preview-meter-dimension u-position-relative o-flex o-flex--column o-flex--align-items-center" *ngIf="metric.visibility">
      <span class="u-margin-bottom-8 u-display-block u-font-weight-400 u-font-size-14 line-height-16 faded-text-color u-text-underline">{{metric.metricName}}</span>
      <mat-icon class="width-100-percent u-height-100" svgIcon="meter-icon"></mat-icon>
      <div class="preview-meter__row-list">
        <span class="preview-meter__row1 u-display-block" *ngIf="metric?.row1" [innerHtml]="getMetricName(metric?.row1)"></span>
        <span class="preview-meter__row2 u-display-block" *ngIf="metric?.row2" [innerHtml]="getMetricName(metric?.row2)"></span>
        <span class="preview-meter__row3 u-display-block" *ngIf="metric?.row3" [innerHtml]="getMetricName(metric?.row3)"></span>
      </div>
    </div>
  </ng-container>
</div>
