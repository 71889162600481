import { ChangeDetectionStrategy, Component, inject, Input } from "@angular/core";
import { ITileConfig } from "@shared/components/interfaces/tile.interface";
import { Router } from "@angular/router";
import { TileTypeNavigationEnum } from "@shared/enums/tile-type-navigation.enum";

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileComponent {
  private router: Router = inject(Router);

  @Input() public config: ITileConfig;
  @Input() public tabIndexToReturn: number;

  public navigateTo(): void {
    let queryParams: Record<string, null | number | undefined | string> = {
      tab_index_to_return: this.tabIndexToReturn,
    };

    if (this.config.navigationType === TileTypeNavigationEnum.FEATURE) {
      queryParams = {...queryParams, configuration: this.config.configuration, feature_id: this.config.id, feature_name: this.config.title}
    } else if (this.config.navigationType === TileTypeNavigationEnum.APP) {
      queryParams = {...queryParams,}
    }

    this.router.navigate([this.config.urlNavigateTo], {queryParams});
  }

  public isBeta(): boolean {
    if (!this.config.tags) {
      return false
    }

    // For now we require that beta be the first tag on the feature
    return this.config.tags[0].toLocaleLowerCase() === "beta";
  }
}
