import { Injectable } from '@angular/core';
import { IPerformanceLimitsConfig } from "@shared/interfaces/performance-limits.interface";
import { AppConstants } from "@core/constants";

@Injectable()
export class VisualizationService {
  private customAthleteCategoryObject: any = {};

  public resetCustomAthleteCategoryObject(): void {
    this.customAthleteCategoryObject = {};
  }

  public generateCustomAthleteCategoryObject(customAthleteCategoryList: any[]): any {
    this.resetCustomAthleteCategoryObject();

    customAthleteCategoryList.forEach((customCategory: any) => {
      this.customAthleteCategoryObject[customCategory.name] = {
        id: customCategory.value,
        name: customCategory.name,
        male_lower: 1,
        male_upper: 1,
        male_stdDev: 1,
        female_lower: 1,
        female_upper: 1,
        female_stdDev: 1,
      };
    });

    return this.customAthleteCategoryObject;
  }

  public transformCustomAthleteCategoryList(customAthleteCategoryList: any[]): any[] {
    return customAthleteCategoryList.map((category: any) => ({
      id: category.id,
      name: category.name,
    }));
  }

  public getDefaultPerformanceLimitsTabConfig(metricName: string, customAthleteCategoryList: any[]): IPerformanceLimitsConfig {
    return {
      custom_tab_object: this.generateCustomAthleteCategoryObject(customAthleteCategoryList),
      tabs: {
        pro: {
          name: 'Pro',
          male_upper: 1,
          male_lower: 1,
          male_stdDev: 1,
          female_upper: 1,
          female_lower: 1,
          female_stdDev: 1,
        },
        amateur: {
          name: 'Amateur',
          male_lower: 1,
          male_upper: 1,
          male_stdDev: 1,
          female_lower: 1,
          female_upper: 1,
          female_stdDev: 1,
        },
        recreational: {
          name: 'Recreational',
          male_lower: 1,
          male_upper: 1,
          male_stdDev: 1,
          female_lower: 1,
          female_upper: 1,
          female_stdDev: 1,
        },
      },
      metricName: metricName,
      description: AppConstants.VISUALIZATION_PAGE.PERFORMANCE_LIMITS_DESCRIPTION,
    }
  }

  public setPerformanceLimitsTabConfig(tabList: any, customTabList: any[],  metricName: string): IPerformanceLimitsConfig {
    return {
      custom_tab_object: customTabList ? customTabList : {},
      tabs: tabList,
      metricName: metricName,
      description: 'Lorem ipsum dolor sit amet consectetur. Orci aliquam nisl vestibulum urna tellus pharetra sem in lobortis. Sed sed magnis sed hendrerit mauris vel suscipit vel.',
    }
  }
}
