import moment from 'moment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute } from '@angular/router';
import { GoogleTagManagerHelperService } from '@shared/services/google-tag-manager-helper.service';
import { StripeElementsOptions } from '@stripe/stripe-js';
import { StripePaymentElementComponent, StripeService, injectStripe } from 'ngx-stripe';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppConstants } from 'src/app/core/constants';
import { ApiService } from 'src/app/core/services/api.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { SessionService } from 'src/app/core/services/session.service';
import { CountryInterface } from './interfaces/country.interface';
import { PlanPaymentSessionInterface, PlanPaymentSessionStatusInterface, ReducedBillingPeriodDetail } from './interfaces/plan-payment-session.interface';
import { PlanComponentState } from './plan.component.state';
import { LoginDetailsInterface } from './interfaces/login-details.interface';
import { PlanPaymentSessionStatusEnum } from './enums/plan-payment-session-status.enum';
import { CustomRendererService } from '@shared/services/custom-renderer.service';
import { SPORT_OPTIONS, TEST_TYPE_OPTIONS, TestTypeOption } from './plan.component.constants';
import { ApiBaseService } from '@core/services/api-base.service';

// Annual by default
const DEFAULT_PERIOD = 1;
const PERIODS = [{
  id: 1,
  name: "Annual",
  slug: "annual"
}, {
  id: 2,
  name: "Quarterly",
  value: "quarter"
}, {
  id: 3,
  name: "Monthly",
  value: "month"
}]

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss'],
  providers: [PlanComponentState]
})
export class PaymentLinkPlanComponent implements OnInit {
  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild(StripePaymentElementComponent) paymentElement!: StripePaymentElementComponent;

  private _destroy$ = new Subject<void>();

  public constants = AppConstants;
  public termsLink = window.location.origin + "/terms";
  public privacyPolicyLink = window.location.origin + "/privacy-policy";

  // Flags
  public isDarkMode: boolean = false;
  public isLoaded: boolean = false;
  public isCountrySelected: boolean = false;

  // State
  public loading$: Observable<boolean>;
  public paymentSessionStatus$?: Observable<PlanPaymentSessionStatusInterface>;
  public paymentSession?: PlanPaymentSessionInterface;
  public countries: CountryInterface[] = [];
  public period = DEFAULT_PERIOD;
  public clientSecret: string;
  public loginDetails: LoginDetailsInterface = { canLogin: false }
  public guid?: string;
  public privacyPolicy$: Observable<string>;
  public terms$: Observable<string>;

  // Forms
  public registrationForm!: FormGroup;
  public periods = PERIODS;
  public elementsOptions: StripeElementsOptions = { locale: 'en' };
  public readonly stripe = injectStripe();
  public testTypeOptions = TEST_TYPE_OPTIONS;
  public sportOptions = SPORT_OPTIONS;
  public step1Editable = true;
  public step2Editable = true;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly apiService: ApiService,
    private readonly apiBaseService: ApiBaseService,
    private readonly authService: AuthService,
    private customRendererService: CustomRendererService,
    private state: PlanComponentState,
    private fb: FormBuilder,
    private googleTagManagerHelperService: GoogleTagManagerHelperService,
    private sessionService: SessionService,
    private readonly stripeService: StripeService,
  ) { }

  ngOnInit(): void {
    this.initStripeOption();
    this.customRendererService.removeClass('body', ['light-theme']);
    this.customRendererService.addClass('body', ['dark-theme']);
    this.loading$ = this.state.loading$;
    this.guid = this.getGuidFromRoute();
    if (!this.guid) {
      // Unreachable - guid should always be set by this point
      return
    }

    this.paymentSessionStatus$ = this.state.paymentSessionStatus$;
    this.state.getPaymentSessionStatus(this.guid);

    this.state.loadState(this.guid);
    this.state.countries$
      .pipe(takeUntil(this._destroy$))
      .subscribe(countries => this.countries = countries);
    this.state.paymentSession$
      .pipe(takeUntil(this._destroy$))
      .subscribe((paymentSession: any) => {
        this.paymentSession = paymentSession
        this.isLoaded = true;
      })

    this.terms$ = this.state.terms$;
    this.privacyPolicy$ = this.state.privacyPolicy$;
    this.initForms();
  }

  private initStripeOption() {
    this.elementsOptions = {
      locale: 'en',
      appearance: {
        theme: 'night',
      },
    };
  }

  private initForms() {
    this.registrationForm = this.fb.group({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      country: new FormControl<number | null>(null, Validators.required),
      consent_accepted: [false, Validators.requiredTrue],
      // Plan purchase details
      period: new FormControl<number | null>(null, Validators.required),
      // Choices
      test_types: new UntypedFormControl(''),
      sports: new UntypedFormControl(''),
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  registerUser() {
    const params: Record<string, any> = this.registrationForm.value;
    params.guid = this.guid;
    params.sports = this.getArrayItems("sports");
    params.test_types = this.getArrayItems("test_types");
    if (params.test_types.length === 0) {
      // Use test types from plan if available
      const planTestTypes = this.paymentSession?.plan.test_types ?? {};
      const testTypes = [];
      for (const testType in planTestTypes) {
        if (planTestTypes[testType]) {
          testTypes.push(testType)
        }
      }
      params.test_types = testTypes;
    }
    const selectedCountry = this.countries.find(c => c.id === this.countryControl.value);
    params.plan = this.paymentSession?.plan?.id;
    params.region = selectedCountry?.region;
    const url = AppConstants.ACCOUNTS_URL.ACCOUNTS + AppConstants.ACCOUNTS_URL.POST.REGISTER;
    this.customRendererService.show(AppConstants.MAT_SPINNER_CLASS);
    this.apiBaseService.postJson<Record<string, any>>(url, params)
      .subscribe({
        next: (response: Record<string, any>) => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          this.clientSecret = response["client_secret"];
          this.elementsOptions.clientSecret = this.clientSecret;
          this.loginDetails = {
            username: response?.username,
            password: response?.password,
            canLogin: response?.can_login
          }
          this.step1Editable = false;
          this.step2Editable = false;
          this.stepper.next();
        },
        error: (error => {
          this.customRendererService.hide(AppConstants.MAT_SPINNER_CLASS);
          if (error?.error?.email) {
            this.registrationForm.controls["email"].setErrors({'email_exists': true})
          } else {
            console.error(error);
          }
          this.stepper.previous();
        })
      })
  }

  public planPricing() {
    const country = this.countries.find(c => c.id === this.countryControl.value);
    if (!country) {
      return undefined
    }

    const planPrice = this.paymentSession?.plan.plan_prices?.find(p => p.region === country.region);
    if (!planPrice) {
      return undefined
    }

    const planPricing: Record<string, any>[] = [];
    if (planPrice?.month) {
      planPricing.push({
        id: "month",
        period: "monthly",
        price: planPrice?.month,
        month_price: planPrice?.month.toFixed(2),
        currency: country.currency
      })
    }
    if (planPrice?.quarter) {
      planPricing.push({
        id: "quarter",
        period: "quarterly",
        price: planPrice?.quarter,
        month_price: (planPrice?.quarter / 3).toFixed(2),
        currency: country.currency
      })
    }
    if (planPrice?.annual) {
      planPricing.push({
        id: "annual",
        period: "annually",
        price: planPrice?.annual,
        month_price: (planPrice?.annual / 12).toFixed(2),
        currency: country.currency
      })
    }

    return planPricing;
  }

  public showBillingPeriod() {
    this.getBillingPeriods();
    // If there is only one billing period set it in the form here and hide the dropdown
    if (this.periods.length === 1) {
      this.registrationForm.controls["period"].setValue(this.periods[0].id)
    }
    return this.periods.length > 1 && this.countryControl?.value;
  }

  public getBillingPeriods() {
    const countryId = this.countryControl?.value;
    if (!countryId) {
      this.periods = PERIODS;
      return;
    }
    const planPricing = this.planPricing();
    if (!planPricing) {
      this.periods = PERIODS;
      return;
    }
    const showPeriods = new Set();
    for (const planPrice of planPricing) {
      switch (planPrice.id) {
        case "month": showPeriods.add(3); break;
        case "quarter": showPeriods.add(2); break;
        case "annual": showPeriods.add(1); break;
        default:
          console.warn("Invalid price period")
          break;
      }
    }
    this.periods = this.periods.filter(period => showPeriods.has(period.id));
  }

  public handlePayment() {
    this.stripeService.confirmPayment({
      elements: this.paymentElement.elements,
      clientSecret: this.paymentElement.clientSecret,
      confirmParams: {
        return_url: location.origin
      },
      redirect: "if_required"
    }).subscribe({
      next: (response: any) => {
        // Username and password will always be set here if canLogin === true
        if (!this.loginDetails.canLogin) {
          return this.stepper.next()
        }
        this.authService.forceLogin({
          username: this.loginDetails?.username ?? '',
          password: this.loginDetails?.password ?? ''
        }, {
          session: this.sessionService,
          api: this.apiService,
          googleTagManagerHelper: this.googleTagManagerHelperService
        })
      },
      error: (error: any) => {
        console.log(error)
      }
    });
  }

  public onSelectTestType(value: any): void {
    this.registrationForm.get('test_types')?.setValue(value);
  }

  public onSelectSport(value: any): void {
    this.registrationForm.get('sports')?.setValue(value);
  }

  public getStatusMessage(status: PlanPaymentSessionStatusEnum): Record<string, string> {
    switch (status) {
      case PlanPaymentSessionStatusEnum.EXPIRED:
        return {
          title: "This payment link has expired",
          message: "Please contact us for a new link"
        }
      case PlanPaymentSessionStatusEnum.CANCELLED:
        return {
          title: "This payment has been cancelled",
          message: "Please contact us if you need additional information"
        }
      case PlanPaymentSessionStatusEnum.FAILED:
        return {
          title: "Payment failed",
          message: "Please contact us if you need additional information"
        }
      case PlanPaymentSessionStatusEnum.PAID:
        return {
          title: "Payment complete",
          message: "This link is no longer valid"
        }
      case PlanPaymentSessionStatusEnum.PROCESSING:
        return {
          title: "This payment is currently being processed",
          message: "Please check again in a short while, or reach out to us"
        }
      default:
        return {
          title: "Invalid payment link",
          message: "We are unable to check the status of this link. Please reach out to us"
        }
    }
  }

  private getRenewalPeriod(periodType: number | undefined = undefined): Record<string, any> | undefined {
    const plan = this.paymentSession?.plan;
    if (!plan) {
      return;
    }

    let period;
    if (!periodType) {
      period = PERIODS.find(period => period.id === plan.renewal_unit);
    } else {
      period = PERIODS.find(period => period.id === periodType);
    }
    return period;
  }

  public getRenewalPeriodText(periodType: number | undefined = undefined): string | boolean {
    const period = this.getRenewalPeriod(periodType);
    if (!period) {
      return false;
    }
    return period?.name ? period.name : false;
  }

  private getBillingPeriodByType(periodType: number): ReducedBillingPeriodDetail | undefined {
    const details = this.paymentSession?.plan?.billing_period_details;
    if (!details) {
      return undefined
    }

    return details.find(detail => detail.period_type === periodType);
  }

  public getMinimumContractDuration(periodType: number | undefined = undefined): number | boolean {
    if (!periodType) {
      return this.paymentSession?.plan.minimum_contract_duration ?? false;
    }

    const billingPeriodDuration = this.getBillingPeriodByType(periodType)?.minimum_contract_duration;
    return billingPeriodDuration ? billingPeriodDuration : false;
  }

  public getCancellationNoticeTime(periodType: number | undefined = undefined): number | boolean {
    if (!periodType) {
      return this.paymentSession?.plan.cancel_notice_time ?? false;
    }
    const billingPeriodCancelNotice = this.getBillingPeriodByType(periodType)?.cancel_notice_time;
    return billingPeriodCancelNotice ? billingPeriodCancelNotice : false;
  }

  public getNextRenewalDate(periodType: number | undefined = undefined): string | boolean {
    const period = this.getRenewalPeriod(periodType);
    if (!period) {
      return false;
    }
    switch (period.id) {
      case 1: {
        return moment().add(1, 'years').format("MMMM Do, YYYY");
      }
      case 2: {
        return moment().add(3, 'months').format("MMMM Do, YYYY");
      }
      case 3: {
        return moment().add(1, 'months').format("MMMM Do, YYYY");
      }
      default: {
        console.warn("Invalid period specified");
        return "";
      }
    }
  }

  public getEarliestCancellationDate(periodType: number | undefined = undefined): string | boolean {
    const billingPeriodDuration = this.getMinimumContractDuration(periodType);
    if (!billingPeriodDuration) {
      return false;
    }
    return moment()
        .add(billingPeriodDuration as number, "months")
        .format("MMMM Do, YYYY");
  }

  public getLatestCancellationNotice(periodType: number | undefined = undefined): string | boolean {
    const billingPeriodDuration = this.getMinimumContractDuration(periodType);
    const cancellationNoticeTime = this.getCancellationNoticeTime(periodType);
    if (!billingPeriodDuration || !cancellationNoticeTime) {
      return false;
    }
    return moment()
        .add(billingPeriodDuration as number, "months")
        .subtract(cancellationNoticeTime as number, "days")
        .format("MMMM Do, YYYY");
  }

  public hasBillingPeriodDetails(periodType: number | undefined = undefined): boolean {
    const billingPeriodDetails = this.getBillingPeriodDetails()
    if (billingPeriodDetails.length === 0) {
      return false
    }

    if (!periodType) {
      return billingPeriodDetails.length > 0;
    }

    return billingPeriodDetails.some(detail => detail.period_type === periodType);
  }

  public getBillingPeriodDetails(): Array<ReducedBillingPeriodDetail> {
    const billingPeriodDetails = this.paymentSession?.plan?.billing_period_details;
    if (!billingPeriodDetails) {
      return []
    }
    return [...billingPeriodDetails].reverse();
  }

  public getTestTypeOptions(): Array<TestTypeOption> {
    if (!this.paymentSession) {
      return this.testTypeOptions;
    }
    const planTestTypes = this.paymentSession.plan.test_types;
    if (!planTestTypes) {
      return this.testTypeOptions;
    }
    let planHasBothTestTypes = true;
    for (const testType in planTestTypes) {
      if (!planTestTypes[testType]) {
        planHasBothTestTypes = false;
      }
    }
    if (planHasBothTestTypes) {
      return []
    }
    return this.testTypeOptions
  }

  // Convenience accessors
  get firstNameControl() { return this.getControlByName("first_name") as FormControl<string | null> }
  get lastNameControl() { return this.getControlByName("last_name") as FormControl<string | null> }
  get emailControl() { return this.getControlByName("email") as FormControl<string | null> }
  get countryControl() { return this.getControlByName("country") as FormControl<number | null> }
  get consentAcceptedControl() { return this.getControlByName("consent_accepted") as FormControl<boolean> }

  private getControlByName(control_name: string, form?: FormGroup) {
    const inputForm = form ?? this.registrationForm;
    return inputForm.get(control_name)
  }

  private getGuidFromRoute(): string | undefined {
    return this.activatedRoute.snapshot.params?.guid ?? undefined;
  }

  private getArrayItems(field: string): string[] {
    const fieldValue = this.registrationForm.get(field)?.value;
    if (!fieldValue) {
      return [];
    }

    return fieldValue.map((item: any) => item.value);
  }
}
