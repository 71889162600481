import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { HelperService } from "@shared/services/helper.service";

@Component({
  selector: 'app-metabolic-configurator',
  templateUrl: './metabolic-configurator.component.html',
  styleUrls: ['./metabolic-configurator.component.scss']
})
export class MetabolicConfiguratorComponent implements OnChanges {
  private fb: FormBuilder = inject(FormBuilder);
  private helperService: HelperService = inject(HelperService);

  public fingerprintForm: FormGroup;
  public fingerprintConfig: any = {};

  @Input() public config: any;
  @Input() public dataPointNumber: number;
  @Input() public metricOptionsConfig: any;
  @Input() public anglePositionNumber: number;
  @Output() public fingerprintConfigChanged: EventEmitter<any> = new EventEmitter<any>();

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.config?.currentValue) {
      this.initFingerPrintForm();
      this.fillFingerprintForm(changes.config.currentValue);
      this.initInitialConfig(changes.config.currentValue);
    }
  }

  public initInitialConfig(config: any): void {
    this.fingerprintConfig = this.helperService.makeCopy(config);
  }

  public initFingerPrintForm(): void {
    this.fingerprintForm = this.fb.group({
      left: [],
      center: [],
      right: [],
    });
    this.disableAllCorners();
    this.enableCornersBasedOnNumber(this.dataPointNumber);
  }

  public disableAllCorners(): void {
    this.fingerprintForm.get('left')?.disable();
    this.fingerprintForm.get('center')?.disable();
    this.fingerprintForm.get('right')?.disable();
  }

  public enableCornersBasedOnNumber(dataPointNumber: number): void {
    if (dataPointNumber === 0 && this.anglePositionNumber === 1) {
      this.fingerprintForm.get('left')?.enable();
    } else if (1 === dataPointNumber) {
      this.fingerprintForm.get('left')?.enable();
    } else if (2 === dataPointNumber) {
      this.fingerprintForm.get('left')?.enable();
      this.fingerprintForm.get('center')?.enable();
    } else if (3 === dataPointNumber) {
      this.fingerprintForm.get('left')?.enable();
      this.fingerprintForm.get('center')?.enable();
      this.fingerprintForm.get('right')?.enable();
    }
  }

  public fillFingerprintForm(config: any): void {
    this.fingerprintForm.patchValue({
      left: config.left?.id,
      center: config.center?.id,
      right: config.right?.id,
    });
  }

  public onChangeLeft(item: any): void {
    this.fingerprintConfig['left'] = item;
    this.fingerprintConfigChanged.emit(this.fingerprintConfig);
  }

  public onChangeCenter(item: any): void {
    this.fingerprintConfig['center'] = item;
    this.fingerprintConfigChanged.emit(this.fingerprintConfig);
  }

  public onChangeRight(item: any): void {
    this.fingerprintConfig['right'] = item;
    this.fingerprintConfigChanged.emit(this.fingerprintConfig);
  }
}
