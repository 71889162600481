import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { IPerformanceLimitsConfig, IPerformanceLimitsTabsConfig, } from "@shared/interfaces/performance-limits.interface";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { debounceTime } from "rxjs/operators";
import { HelperService } from '@shared/services/helper.service';

const ValidateFields = [
  "male_upper",
  "male_lower",
  "male_stdDev",
  "female_upper",
  "female_lower",
  "female_stdDev",
]

@Component({
  selector: 'app-performance-limits',
  templateUrl: './performance-limits.component.html',
  styleUrls: ['./performance-limits.component.scss'],
})
export class PerformanceLimitsComponent implements OnChanges {
  private fb: FormBuilder = inject(FormBuilder);
  private helperService: HelperService = inject(HelperService);

  public performanceLimitsProForm: FormGroup;
  public performanceLimitsAmateurForm: FormGroup;
  public performanceLimitsRecreationalForm: FormGroup;
  public performanceLimitsCustomForm1: FormGroup | undefined;
  public performanceLimitsCustomForm2: FormGroup | undefined;
  public performanceLimitsCustomForm3: FormGroup | undefined;
  public performanceLimitsCustomForm4: FormGroup | undefined;
  public performanceLimitsCustomForm5: FormGroup | undefined;
  public performanceLimitsCustomForm6: FormGroup | undefined;

  @Input() public config: IPerformanceLimitsConfig;
  @Input() public angleName: string;
  @Input() public dataPointNumber: number;
  @Input() public anglePositionNumber: number;
  @Input() public isApplyOverlay: boolean;
  @Input() public referenceSystemId: number | undefined;
  @Output() performanceLimitChanged: EventEmitter<any> = new EventEmitter<any>();

  public ngOnChanges(changes: SimpleChanges): void {
    this.initPerformanceLimitsDefaultFormList();
    this.resetCustomTabList();
    this.initAndFillCustomTabs();
    this.fillAllFormList(changes.config.currentValue);
  }

  public initPerformanceLimitsDefaultFormList(): void {
    this.performanceLimitsProForm = this.fb.group({
      name: [''],
      male_lower: [1],
      male_upper: [1],
      male_stdDev: [1],
      female_lower: [1],
      female_upper: [1],
      female_stdDev: [1],
    });

    this.performanceLimitsAmateurForm = this.fb.group({
      name: [''],
      male_lower: [1],
      male_upper: [1],
      male_stdDev: [1],
      female_lower: [1],
      female_upper: [1],
      female_stdDev: [1],
    });

    this.performanceLimitsRecreationalForm = this.fb.group({
      name: [''],
      male_lower: [1],
      male_upper: [1],
      male_stdDev: [1],
      female_lower: [1],
      female_upper: [1],
      female_stdDev: [1],
    });
  }

  public checkUpperLowerForm1(): void {
    if (this.performanceLimitsCustomForm1) {
      this.performanceLimitsCustomForm1.controls['is_default_value'].setValue(false);
      this.validateForm(this.performanceLimitsCustomForm1);
      this.setStandardDeviation(this.performanceLimitsCustomForm1);
    }
  }

  public checkUpperLowerForm2(): void {
    if (this.performanceLimitsCustomForm2) {
      this.performanceLimitsCustomForm2.controls['is_default_value'].setValue(false);
      this.validateForm(this.performanceLimitsCustomForm2);
      this.setStandardDeviation(this.performanceLimitsCustomForm2);
    }
  }

  public checkUpperLowerForm3(): void {
    if (this.performanceLimitsCustomForm3) {
      this.performanceLimitsCustomForm3.controls['is_default_value'].setValue(false);
      this.validateForm(this.performanceLimitsCustomForm3);
      this.setStandardDeviation(this.performanceLimitsCustomForm3);
    }
  }

  public checkUpperLowerForm4(): void {
    if (this.performanceLimitsCustomForm4) {
      this.performanceLimitsCustomForm4.controls['is_default_value'].setValue(false);
      this.validateForm(this.performanceLimitsCustomForm4);
      this.setStandardDeviation(this.performanceLimitsCustomForm4);
    }
  }

  public checkUpperLowerForm5(): void {
    if (this.performanceLimitsCustomForm5) {
      this.performanceLimitsCustomForm5.controls['is_default_value'].setValue(false);
      this.validateForm(this.performanceLimitsCustomForm5);
      this.setStandardDeviation(this.performanceLimitsCustomForm5);
    }
  }

  public checkUpperLowerForm6(): void {
    if (this.performanceLimitsCustomForm6) {
      this.performanceLimitsCustomForm6.controls['is_default_value'].setValue(false);
      this.validateForm(this.performanceLimitsCustomForm6);
      this.setStandardDeviation(this.performanceLimitsCustomForm6);
    }
  }

  public emitAllFormsData(): void {
    let performanceLimitsObject: any = {
      tabs: {
        pro: this.performanceLimitsProForm.value,
        amateur: this.performanceLimitsAmateurForm.value,
        recreational: this.performanceLimitsRecreationalForm.value,
      },
      custom_tab_object: {},
    };

    let dataIsValid = true;
    if (this.performanceLimitsCustomForm1) {
      dataIsValid = dataIsValid && this.performanceLimitsCustomForm1.valid;
      performanceLimitsObject = {
        ...performanceLimitsObject,
        custom_tab_object: {
          ...performanceLimitsObject.custom_tab_object,
          [this.performanceLimitsCustomForm1.get('name')?.value]: this.convertReferenceSystemToMS(this.performanceLimitsCustomForm1.value)
        }
      };
    }
    if (this.performanceLimitsCustomForm2) {
      dataIsValid = dataIsValid && this.performanceLimitsCustomForm2.valid;
      performanceLimitsObject = {
        ...performanceLimitsObject,
        custom_tab_object: {
          ...performanceLimitsObject.custom_tab_object,
          [this.performanceLimitsCustomForm2.get('name')?.value]: this.convertReferenceSystemToMS(this.performanceLimitsCustomForm2.value)
        }
      };
    }
    if (this.performanceLimitsCustomForm3) {
      dataIsValid = dataIsValid && this.performanceLimitsCustomForm3.valid;
      performanceLimitsObject = {
        ...performanceLimitsObject,
        custom_tab_object: {
          ...performanceLimitsObject.custom_tab_object,
          [this.performanceLimitsCustomForm3.get('name')?.value]: this.convertReferenceSystemToMS(this.performanceLimitsCustomForm3.value)
        }
      };
    }
    if (this.performanceLimitsCustomForm4) {
      dataIsValid = dataIsValid && this.performanceLimitsCustomForm4.valid;
      performanceLimitsObject = {
        ...performanceLimitsObject,
        custom_tab_object: {
          ...performanceLimitsObject.custom_tab_object,
          [this.performanceLimitsCustomForm4.get('name')?.value]: this.convertReferenceSystemToMS(this.performanceLimitsCustomForm4.value)
        }
      };
    }
    if (this.performanceLimitsCustomForm5) {
      dataIsValid = dataIsValid && this.performanceLimitsCustomForm5.valid;
      performanceLimitsObject = {
        ...performanceLimitsObject,
        custom_tab_object: {
          ...performanceLimitsObject.custom_tab_object,
          [this.performanceLimitsCustomForm5.get('name')?.value]: this.convertReferenceSystemToMS(this.performanceLimitsCustomForm5.value)
        }
      };
    }
    if (this.performanceLimitsCustomForm6) {
      dataIsValid = dataIsValid && this.performanceLimitsCustomForm6.valid;
      performanceLimitsObject = {
        ...performanceLimitsObject,
        custom_tab_object: {
          ...performanceLimitsObject.custom_tab_object,
          [this.performanceLimitsCustomForm6.get('name')?.value]: this.convertReferenceSystemToMS(this.performanceLimitsCustomForm6.value)
        }
      };
    }

    if (dataIsValid) {
      this.performanceLimitChanged.emit(performanceLimitsObject);
    }
  }

  public setStandardDeviation(form: FormGroup | undefined): void {
    if (form && this.referenceSystemId && [6,7,8,9].includes(this.referenceSystemId)) {
      const upperMale = this.paceToSeconds(form?.get('male_upper')?.value || "1:00");
      const lowerMale = this.paceToSeconds(form?.get('male_lower')?.value || "1:00");
      const upperFemale = this.paceToSeconds(form?.get('female_upper')?.value || "1:00");
      const lowerFemale = this.paceToSeconds(form?.get('female_lower')?.value || "1:00");

      const maleStdDev: any = form.get('male_stdDev');
      const femaleStdDev: any = form.get('female_stdDev');

      const stdDevMale: number = Math.abs(upperMale - lowerMale) / 6;
      const stdDevFemale: number = Math.abs(upperFemale - lowerFemale) / 6;

      maleStdDev.setValue(stdDevMale.toFixed(2));
      femaleStdDev.setValue(stdDevFemale.toFixed(2));
      return
    }

    if (form) {
      console.log('check');
      console.log(form.get('male_stdDev')?.value)
      const upperMale: number = form.get('male_upper')?.value || 1;
      const lowerMale: number = form.get('male_lower')?.value || 1;
      const upperFemale: number = form.get('female_upper')?.value || 1;
      const lowerFemale: number = form.get('female_lower')?.value || 1;
      const maleStdDev: any = form.get('male_stdDev');
      const femaleStdDev: any = form.get('female_stdDev');

      const stdDevMale: number = Math.abs(upperMale - lowerMale) / 6;
      const stdDevFemale: number = Math.abs(upperFemale - lowerFemale) / 6;

      maleStdDev.setValue(stdDevMale.toFixed(2));
      femaleStdDev.setValue(stdDevFemale.toFixed(2));
    }
  }

  public subscribeForAllFormChanges(): void {
    this.performanceLimitsProForm.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData();},});
    this.performanceLimitsAmateurForm.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
    this.performanceLimitsRecreationalForm.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
    this.performanceLimitsCustomForm1?.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData();},});
    this.performanceLimitsCustomForm2?.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
    this.performanceLimitsCustomForm3?.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
    this.performanceLimitsCustomForm4?.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
    this.performanceLimitsCustomForm5?.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
    this.performanceLimitsCustomForm6?.valueChanges
      .pipe(debounceTime(1000))
      .subscribe({next: () => {this.emitAllFormsData()},});
  }

  public resetCustomTabList(): void {
    this.performanceLimitsCustomForm1 = undefined;
    this.performanceLimitsCustomForm2 = undefined;
    this.performanceLimitsCustomForm3 = undefined;
    this.performanceLimitsCustomForm4 = undefined;
    this.performanceLimitsCustomForm5 = undefined;
    this.performanceLimitsCustomForm6 = undefined;
  }

  public initAndFillCustomTabs(): void {
    let tabObjectList: any[] = [];

    for(let customTab in this.config.custom_tab_object) {
      const clonedCustomTab = this.helperService.makeCopy(this.config.custom_tab_object[customTab]);
      tabObjectList.push(this.convertTabsToReferenceSystem(clonedCustomTab));
    }

    tabObjectList.forEach((tab: IPerformanceLimitsTabsConfig, index: number) => {
      if ((index + 1) === 1) {
        this.performanceLimitsCustomForm1 = this.fb.group({
          id: tab.id,
          name: [tab.name],
          male_lower: [tab.male_lower ? tab.male_lower : 1],
          male_upper: [tab.male_upper ? tab.male_upper : 1],
          male_stdDev: [tab.male_stdDev ? tab.male_stdDev : 1],
          female_lower: [tab.female_lower ? tab.female_lower : 1],
          female_upper: [tab.female_upper ? tab.female_upper : 1],
          female_stdDev: [tab.female_stdDev ? tab.female_stdDev : 1],
          is_default_value: [tab.is_default_value ? tab.is_default_value : false],
        });
      }

      if ((index + 1) === 2) {
        this.performanceLimitsCustomForm2 = this.fb.group({
          id: tab.id,
          name: [tab.name],
          male_lower: [tab.male_lower ? tab.male_lower : 1],
          male_upper: [tab.male_upper ? tab.male_upper : 1],
          male_stdDev: [tab.male_stdDev ? tab.male_stdDev : 1],
          female_lower: [tab.female_lower ? tab.female_lower : 1],
          female_upper: [tab.female_upper ? tab.female_upper : 1],
          female_stdDev: [tab.female_stdDev ? tab.female_stdDev : 1],
          is_default_value: [tab.is_default_value ? tab.is_default_value : false],
        });
      }
      if ((index + 1) === 3) {
        this.performanceLimitsCustomForm3 = this.fb.group({
          id: tab.id,
          name: [tab.name],
          male_lower: [tab.male_lower ? tab.male_lower : 1],
          male_upper: [tab.male_upper ? tab.male_upper : 1],
          male_stdDev: [tab.male_stdDev ? tab.male_stdDev : 1],
          female_lower: [tab.female_lower ? tab.female_lower : 1],
          female_upper: [tab.female_upper ? tab.female_upper : 1],
          female_stdDev: [tab.female_stdDev ? tab.female_stdDev : 1],
          is_default_value: [tab.is_default_value ? tab.is_default_value : false],
        });
      }

      if ((index + 1) === 4) {
        this.performanceLimitsCustomForm4 = this.fb.group({
          id: tab.id,
          name: [tab.name],
          male_lower: [tab.male_lower ? tab.male_lower : 1],
          male_upper: [tab.male_upper ? tab.male_upper : 1],
          male_stdDev: [tab.male_stdDev ? tab.male_stdDev : 1],
          female_lower: [tab.female_lower ? tab.female_lower : 1],
          female_upper: [tab.female_upper ? tab.female_upper : 1],
          female_stdDev: [tab.female_stdDev ? tab.female_stdDev : 1],
          is_default_value: [tab.is_default_value ? tab.is_default_value : false],
        });
      }
      if ((index + 1) === 5) {
        this.performanceLimitsCustomForm5 = this.fb.group({
          id: tab.id,
          name: [tab.name],
          male_lower: [tab.male_lower ? tab.male_lower : 1],
          male_upper: [tab.male_upper ? tab.male_upper : 1],
          male_stdDev: [tab.male_stdDev ? tab.male_stdDev : 1],
          female_lower: [tab.female_lower ? tab.female_lower : 1],
          female_upper: [tab.female_upper ? tab.female_upper : 1],
          female_stdDev: [tab.female_stdDev ? tab.female_stdDev : 1],
          is_default_value: [tab.is_default_value ? tab.is_default_value : false],
        });
      }
      if ((index + 1) === 6) {
        this.performanceLimitsCustomForm6 = this.fb.group({
          id: tab.id,
          name: [tab.name],
          male_lower: [tab.male_lower ? tab.male_lower : 1],
          male_upper: [tab.male_upper ? tab.male_upper : 1],
          male_stdDev: [tab.male_stdDev ? tab.male_stdDev : 1],
          female_lower: [tab.female_lower ? tab.female_lower : 1],
          female_upper: [tab.female_upper ? tab.female_upper : 1],
          female_stdDev: [tab.female_stdDev ? tab.female_stdDev : 1],
          is_default_value: [tab.is_default_value ? tab.is_default_value : false],
        });
      }
    });
  }

  public fillAllFormList(config: IPerformanceLimitsConfig): void {
    const proTab = this.helperService.makeCopy(config.tabs.pro);
    const amateurTab = this.helperService.makeCopy(config.tabs.amateur);
    const recreationalTab = this.helperService.makeCopy(config.tabs.recreational);

    // Render cloned tabs so as not to change the m/s values in the actual config on save
    this.performanceLimitsProForm.patchValue(this.convertTabsToReferenceSystem(proTab));
    this.performanceLimitsAmateurForm.patchValue(this.convertTabsToReferenceSystem(amateurTab));
    this.performanceLimitsRecreationalForm.patchValue(this.convertTabsToReferenceSystem(recreationalTab));

    this.subscribeForAllFormChanges();
  }

  public convertTabsToReferenceSystem(tab: IPerformanceLimitsTabsConfig): IPerformanceLimitsTabsConfig {
    // Recalculate stdDev for converted values
    let defaultValue: number | string = 1;
    if (this.referenceSystemId && [6,7,8,9].includes(this.referenceSystemId)) {
      defaultValue = "1:00";
    }

    // Note that we are allowing for 0 values here
    if (tab.male_upper != null && tab.male_lower != null) {
      const upperMale = this.convertFromMS(tab.male_upper as number, true) as number;
      const lowerMale = this.convertFromMS(tab.male_lower as number, true) as number;
      tab.male_stdDev = ((Math.abs(upperMale - lowerMale)) / 6).toFixed(2);
    } else {
      tab.male_stdDev = defaultValue;
    }

    if (tab.female_lower != null && tab.female_upper != null) {
      const upperFemale = this.convertFromMS(tab.female_upper as number, true) as number;
      const lowerFemale = this.convertFromMS(tab.female_lower as number, true) as number;
      tab.female_stdDev = ((Math.abs(upperFemale - lowerFemale)) / 6).toFixed(2);
    } else {
      tab.female_stdDev = defaultValue;
    }

    tab.male_lower = tab.male_lower != null ? this.convertFromMS(tab.male_lower as number) : defaultValue;
    tab.male_upper = tab.male_upper!= null ? this.convertFromMS(tab.male_upper as number) : defaultValue;
    tab.female_lower = tab.female_lower != null ? this.convertFromMS(tab.female_lower as number) : defaultValue;
    tab.female_upper = tab.female_upper != null ? this.convertFromMS(tab.female_upper as number) : defaultValue;
    return tab;
  }

  public convertReferenceSystemToMS(tab: IPerformanceLimitsTabsConfig): IPerformanceLimitsTabsConfig {
    tab.male_lower = this.convertToMs(tab.male_lower as number);
    tab.male_upper = this.convertToMs(tab.male_upper as number);
    tab.female_lower = this.convertToMs(tab.female_lower as number);
    tab.female_upper = this.convertToMs(tab.female_upper as number);

    tab.male_stdDev = (Math.abs((tab.male_upper as number) - (tab.male_lower as number))) / 6;
    tab.female_stdDev = (Math.abs((tab.female_upper as number) - (tab.female_lower as number))) / 6;
    return tab;
  }

  public convertFromMS(value: number, returnSeconds = false): number | string {
    if (!this.referenceSystemId) {
      return value
    }

    // Already m/s
    if (this.referenceSystemId < 4) {
      return value
    }

    // Numeric convertions
    switch (this.referenceSystemId) {
      case 4:
        // Km/h
        return value * 3.6;
      case 5:
        // mph
        return value * 3.6 / 1.61;
      case 6: {
        // min:sec/km
        const timeInSeconds = 1000 / value;
        return returnSeconds ? timeInSeconds : this.timeToMinSec(timeInSeconds);
      }
      case 7: {
        // min:sec/mile
        const timeInSeconds = 1000 * 1.61 / value;
        return returnSeconds ? timeInSeconds : this.timeToMinSec(timeInSeconds);
      }
      case 8: {
        // min:sec/100m
        const timeInSeconds = 100 / value;
        return returnSeconds ? timeInSeconds : this.timeToMinSec(timeInSeconds);
      }
      case 9: {
        // min:sec/500m
        const timeInSeconds = 500 / value;
        return returnSeconds ? timeInSeconds : this.timeToMinSec(timeInSeconds);
      }
      default:
        return value
    }
  }

  public convertToMs(value: string | number): number {
    if (!this.referenceSystemId) {
      return typeof value === "number" ? value : parseFloat(value);
    }

    if (this.referenceSystemId < 4) {
      return typeof value === "number" ? value : parseFloat(value);
    }

    switch (this.referenceSystemId) {
      case 4:
        return (value as number) / 3.6;
      case 5:
        return (value as number) * 1.61 / 3.6;
      case 6: {
        const timeInSeconds = this.paceToSeconds(value as string);
        return 1000 / timeInSeconds;
      }
      case 7: {
        const timeInSeconds = this.paceToSeconds(value as string);
        return 1000 * 1.61 / timeInSeconds;
      }
      case 8: {
        const timeInSeconds = this.paceToSeconds(value as string);
        return 100 / timeInSeconds;
      }
      case 9: {
        const timeInSeconds = this.paceToSeconds(value as string);
        return 500 / timeInSeconds;
      }
      default:
        return typeof value === "number" ? value : parseFloat(value);
    }
  }

  /**
   * Converts a value in seconds such as 183.75634 into a min:sec value such as
   * 3:03.7
   */
  public timeToMinSec(timeInSeconds: number): string {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds - (minutes * 60);
    let secondsString = seconds.toFixed(1);
    if (seconds < 10) {
      secondsString = `0${secondsString}`
    }
    return `${minutes}:${secondsString}`;
  }

  public paceToSeconds(paceValue: string): number {
    const [minutes, seconds] = paceValue.split(":");
    return parseFloat(minutes) * 60 + parseFloat(seconds);
  }

  public isTextInput(): boolean {
    if (!this.referenceSystemId) {
      return false;
    }

    if ([6, 7, 8, 9].includes(this.referenceSystemId)) {
      return true;
    }

    return false;
  }

  private isAboveZero(value: number | string): boolean {
    if (typeof value === "string")  {
      return value[0] !== "-"
    }
    return value >= 0;
  }

  private validateForm(formGroup: FormGroup<any>) {
    const formValues = formGroup.value;
    for (const fieldName of ValidateFields) {
      if (!this.isAboveZero(formValues[fieldName])) {
        formGroup.controls[fieldName]?.setErrors({"min": true})
      }
    }
  }
}
